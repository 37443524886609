import React, { useEffect, useState, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Moment from 'react-moment';
import { switchSidebarLogo, trasformaCifra, getDcumentoPrint } from '../../../utility/Utility';
import condizioniGenerali from '../../../img/Condizionigenerali.jpg'
import { modificaPagamento, annullaConsegnaOrdine } from '../../../actions/ordine';
import ModificaOrdineConfermato from './ModificaOrdineConfermato'
const OrdineConsegnato = ({ auth: { user }, ordine, onClickChiudiOrdine, setCorrezioneOpen, modificaPagamento, annullaConsegnaOrdine }) => {


    const [openModificaOrdine, setOpenModificaOrdine] = useState(false);

    const [datiCliente, setDatiCliente] = useState({
        idOrdine: '',
        pIvaCodiceFiscale: '',
        nome: '',
        cognome: '',
        telefono_1: '',
        telefono_2: '',
        telefono_3: '',
        telefono_4: '',
        email_1: '',
        email_2: '',
        indirizzo: '',
        cap: '',
        citta: '',
        indirizzoPreventivo: '',
        capPreventivo: '',
        cittaPreventivo: '',
        pianoPreventivo: '',
        scalaPreventivo: '',
        ascensorePreventivo: '',
        ztlPreventivo: '',
        nomePuntoVendita: '',
        indirizzoPuntoVendita: '',
        telefonoPuntoVendita: '',
        emailPuntoVendita: '',
        sitoWebPuntoVendita: '',
        emailVenditore: '',
        nomeVenditore: '',
        cognomeVenditore: '',
        numero: '',
        date: '',
        magazzino: '',
        misure: '',
        fattura: '',
        elementi: [],
        acconto: '',
        accontiPersonalizzati : [
            {
                nomeAcconto:'',
                cifraAcconto: null
            },{
                nomeAcconto:'',
                cifraAcconto: null
            }
        ],
        saldo: '',
        totale: '',
        dataConsegnaStart: new Date(),
        dataConsegnaEnd: new Date(),
        montatore: '',
        nomeMontatore: '',
        cognomeMontatore: '',
        ordineRiparazione: false,
        ordineRiparato: false,
        descrizioneGenerale: '',
        note: '',
        notePagamento: '',
        metodoPagamento: '',
        notePreventivo: '',
        statoPagamento: '',
        dataConfermaPreventivo: new Date(),
        costoProgetto: {
            prezzo: 0,
            conteggiato: false
        },
        costoMisure: {
            prezzo: 0,
            conteggiato: false
        },
        costoMagazzino: {
            prezzo: 0,
            conteggiato: false
        },
        costoSmontaggio: {
            prezzo: 0,
            conteggiato: false
        },
        costoElettricista: {
            prezzo: 0,
            conteggiato: false
        },
        costoIdraulico: {
            prezzo: 0,
            conteggiato: false
        },
        costoElevatore: {
            prezzo: 0,
            conteggiato: false
        },
        fileDocumenti: ''
    });


    useEffect(() => {
        setDatiCliente({
            idOrdine: !ordine ? '' : ordine._id,
            pIvaCodiceFiscale: !ordine ? '' : ordine.cliente.pIvaCodiceFiscale,
            nome: !ordine ? '' : ordine.cliente.nome,
            cognome: !ordine ? '' : ordine.cliente.cognome,
            telefono_1: !ordine ? '' : ordine.cliente.telefono_1,
            telefono_2: !ordine ? '' : ordine.cliente.telefono_2,
            telefono_3: !ordine ? '' : ordine.cliente.telefono_3,
            telefono_4: !ordine ? '' : ordine.cliente.telefono_4,
            email_1: !ordine ? '' : ordine.cliente.email_1,
            email_2: !ordine ? '' : ordine.cliente.email_2,
            indirizzo: !ordine ? '' : ordine.cliente.indirizzo,
            cap: !ordine ? '' : ordine.cliente.cap,
            citta: !ordine ? '' : ordine.cliente.citta,
            indirizzoPreventivo: !ordine ? '' : ordine.indirizzoPreventivo,
            capPreventivo: !ordine ? '' : ordine.capPreventivo,
            cittaPreventivo: !ordine ? '' : ordine.cittaPreventivo,
            pianoPreventivo: !ordine ? '' : ordine.pianoPreventivo,
            scalaPreventivo: !ordine ? '' : ordine.scalaPreventivo,
            ascensorePreventivo: !ordine ? '' : ordine.ascensorePreventivo,
            ztlPreventivo: !ordine ? '' : ordine.ztlPreventivo,
            nomePuntoVendita: !ordine ? '' : ordine.puntoVendita.nome,
            nomePuntoVenditaDb: !ordine ? '' : ordine.puntoVendita.nomeDb,
            indirizzoPuntoVendita: !ordine ? '' : ordine.puntoVendita.indirizzo,
            telefonoPuntoVendita: !ordine ? '' : ordine.puntoVendita.telefono,
            emailPuntoVendita: !ordine ? '' : ordine.puntoVendita.email,
            sitoWebPuntoVendita: !ordine ? '' : ordine.puntoVendita.sitoWeb,
            nomeVenditore: !ordine ? '' : ordine.venditore.nome,
            cognomeVenditore: !ordine ? '' : ordine.venditore.cognome,
            emailVenditore: !ordine ? '' : ordine.venditore.email,
            fileDocumenti: !ordine ? '' : ordine.venditore.fileDocumenti,
            numero: !ordine ? '' : ordine.numero,
            date: !ordine ? '' : ordine.date,
            misure: !ordine ? '' : ordine.misure,
            fattura: !ordine ? '' : ordine.fattura,
            elementi: !ordine ? '' : ordine.elementi,
            acconto: !ordine ? '' : ordine.acconto,
            accontiPersonalizzati: !ordine || !ordine.accontiPersonalizzati || ordine.accontiPersonalizzati.length <= 0 ? [
                {
                    nomeAcconto:'',
                    cifraAcconto: null
                },{
                    nomeAcconto:'',
                    cifraAcconto: null
                }
            ] : ordine.accontiPersonalizzati,
            saldo: !ordine ? '' : ordine.saldo,
            totale: !ordine ? '' : ordine.totale,
            dataConsegnaStart: !ordine || ordine.dataConsegnaStart === undefined ? new Date() : new Date(ordine.dataConsegnaStart),
            dataConsegnaEnd: !ordine || ordine.dataConsegnaEnd === undefined ? new Date() : new Date(ordine.dataConsegnaEnd),
            nomeMontatore: !ordine || !ordine.montatore ? '' : ordine.montatore.nome,
            cognomeMontatore: !ordine || !ordine.montatore ? '' : ordine.montatore.cognome,
            ordineRiparazione: !ordine ? false : ordine.ordineRiparazione,
            ordineRiparato: !ordine ? false : ordine.ordineRiparato,
            descrizioneGenerale: !ordine ? '' : ordine.descrizioneGenerale,
            note: !ordine ? '' : ordine.note,
            notePagamento: !ordine ? '' : ordine.notePagamento,
            notePreventivo: !ordine ? '' : ordine.notePreventivo,
            metodoPagamento: !ordine ? '' : ordine.metodoPagamento,
            statoPagamento: !ordine ? '' : ordine.statoPagamento,
            dataConfermaPreventivo: !ordine ||  ordine.dataConfermaPreventivo === undefined ? new Date() : new Date(ordine.dataConfermaPreventivo),
            costoProgetto: !ordine || !ordine.costoProgetto ? {
                prezzo: 0,
                conteggiato: false
            } : ordine.costoProgetto,
            costoMisure: !ordine || !ordine.costoMisure ? {
                prezzo: 0,
                conteggiato: false
            } : ordine.costoMisure,
            costoMagazzino: !ordine || !ordine.costoMagazzino ? {
                prezzo: 0,
                conteggiato: false
            } : ordine.costoMagazzino,
            costoSmontaggio: !ordine || !ordine.costoSmontaggio ? {
                prezzo: 0,
                conteggiato: false
            } : ordine.costoSmontaggio,
            costoElettricista: !ordine || !ordine.costoElettricista ? {
                prezzo: 0,
                conteggiato: false
            } : ordine.costoElettricista,
            costoIdraulico: !ordine || !ordine.costoIdraulico ? {
                prezzo: 0,
                conteggiato: false
            } : ordine.costoIdraulico,
            costoElevatore: !ordine || !ordine.costoElevatore ? {
                prezzo: 0,
                conteggiato: false
            } : ordine.costoElevatore
        });
    }, [ordine]);

    const { idOrdine, pIvaCodiceFiscale, nome, cognome, telefono_1, telefono_2, telefono_3, telefono_4, email_1, email_2, indirizzo, cap, citta, indirizzoPreventivo, capPreventivo, cittaPreventivo, pianoPreventivo, scalaPreventivo, ascensorePreventivo, ztlPreventivo, nomePuntoVendita, nomePuntoVenditaDb, indirizzoPuntoVendita, telefonoPuntoVendita, emailPuntoVendita, sitoWebPuntoVendita, nomeVenditore, cognomeVenditore, emailVenditore, numero, misure, fattura, elementi, acconto, accontiPersonalizzati, saldo, totale, dataConsegnaStart, nomeMontatore, cognomeMontatore, ordineRiparazione, ordineRiparato, descrizioneGenerale, note, notePagamento, notePreventivo, metodoPagamento, statoPagamento, dataConfermaPreventivo, costoProgetto, costoMisure, costoMagazzino, costoSmontaggio, costoElettricista, costoIdraulico, costoElevatore, fileDocumenti } = datiCliente;

    const [abilitaVista, setAbilitaVista] = useState(false);

    const onChangeSetVistaStampa = e => setAbilitaVista(e.target.checked);

    const onChangesetDatiOrdine = e => setDatiCliente({ ...datiCliente, [e.target.name]: e.target.value });

    const createUIElementi = () => {
        return (
            <Fragment>
                <thead>
                    <tr>
                    <th class="text-left pr-0 font-weight-bold  text-uppercase">Fornitore</th>
                    <th class="pr-0 font-weight-bold   text-uppercase">Descrizione</th>
                    <th class={`text-right font-weight-bold  text-uppercase ${ordineRiparazione ? 'noprint': null}`}>Prezzo Listino</th>
                    <th class={`text-right font-weight-bold  text-uppercase ${ordineRiparazione ? 'noprint': null}`}>Prezzo Scontato</th>
                    </tr>
                </thead>
                <tbody>
                    {elementi.length > 0 ?
                        elementi.map((elemento) => (
                            <tr class="font-weight-boldest">
                                <td class=" pt-7">
                                    {elemento.marca}
                                 </td>
                                 <td class=" pt-7">
                                    {elemento.descrizione}
                                </td>
                                <td class={`text-right pt-7 align-middle ${ordineRiparazione ? 'noprint': null}`}>{trasformaCifra(elemento.prezzoListino)}€</td>
                                <td class={`text-right pt-7 align-middle ${ordineRiparazione ? 'noprint': null}`}>{trasformaCifra(elemento.prezzo)}€</td>
                            </tr>
                        )) : null
                    }
                </tbody>
            </Fragment>
        )
    }

    const createUIElementiSemplificata = () => {
        return (
            <Fragment>
                <thead>
                    <tr>
                        <th class="text-left pr-0 font-weight-bold  text-uppercase">Fornitore</th>
                        <th class="pr-0 font-weight-bold   text-uppercase">Descrizione</th>
                        <th class={`text-right font-weight-bold  text-uppercase ${ordineRiparazione ? 'noprint' : null}`}>Prezzo Lisitno</th>
                        <th class={`text-right font-weight-bold  text-uppercase ${ordineRiparazione ? 'noprint' : null}`}>Prezzo Scontato</th>
                    </tr>
                </thead>
                <tbody>
                    {elementi.length > 0 ?
                        elementi.map((elemento, i) => (
                            <tr class="font-weight-boldest">
                                <td class="pt-7 align-middle">
                                    {elemento.marca}
                                </td>
                                <td class="pt-7 align-middle mag-desc">
                                    {elemento.descrizione}
                                </td>
                                <td class={`text-right pt-7 align-middle ${ordineRiparazione ? 'noprint' : null}`}>{trasformaCifra(elemento.prezzoListino)}€</td>
                                <td class={`text-right pt-7 align-middle ${ordineRiparazione ? 'noprint' : null}`}>{trasformaCifra(elemento.prezzo)}€</td>                                
                            </tr>

                        )) : null
                    }
                </tbody>
            </Fragment>
        )
    }

    const openDocumenti = () => {
        let objFra = document.createElement('iframe');     // Create an IFrame.
        objFra.style.visibility = 'hidden';   
        // Hide the frame.
        objFra.src = getDcumentoPrint(fileDocumenti);                   // Set source.

        document.body.appendChild(objFra);  // Add the frame to the web page.

        objFra.contentWindow.focus();       // Set focus.
        objFra.contentWindow.print(); 
    }

    const onClickSegnaComeSaldato = ({idOrdine}) => {
        modificaPagamento({idOrdine, statoPagamento, metodoPagamento, notePagamento})
    }

    const onClickOpenModificaOrdine = () => {
        setOpenModificaOrdine(true);
    }

    const onClickAnnullaOrdine = (idOrdine) => {
        annullaConsegnaOrdine(idOrdine);
    }

    return (
        openModificaOrdine ?
            <ModificaOrdineConfermato
                ordine={ordine}
                setOpenModificaOrdine={setOpenModificaOrdine}
            />
            :
        <Fragment>
        <div className="card card-custom card-shadowless rounded-top-0" id="preventivo">
            <div class="card-body p-0">
                <iframe id="ifmcontentstoprint" style={{ height: "0px", width: "0px", position: "absolute" }}>
                </iframe>
                <div class="row justify-content-center py-8 px-8 py-md-10 px-md-0">
                    <div class="col-md-10">
                    <div class="d-flex justify-content-between pb-10 pb-md-20 flex-column flex-md-row print-orizzontale">
                    <div class="d-flex flex-column px-0 flex-root logo-mobile">
                                <div class="logo-stampa">
                                    <img alt="Logo" src={switchSidebarLogo(nomePuntoVenditaDb, user.role, 'grande')} className="max-h-150px" />
                                </div>
                                <h1>{nomePuntoVendita}</h1>
                                <span class=" d-flex flex-column">
                                    <span>{indirizzoPuntoVendita}</span>
                                    <span>{telefonoPuntoVendita}</span>
                                    <span>Ufficio consegne: 0382 573181</span>
                                    <div>Arredatore: <span className="text-nome-cognome">{nomeVenditore} {cognomeVenditore}</span><span> - {emailVenditore}</span></div>
                                    <span>{sitoWebPuntoVendita}</span>
                                    {nomePuntoVenditaDb !== 'arca_arredamenti' ?
                                        <span style={{"color":"red"}}>Instestato a: ARCA ARREDAMENTI CASA SRL</span>
                                    : 
                                        ''
                                    }  
                                    <span style={{"color":"red"}}>Intesa Sanpaolo - IBAN: IT 82O0 3069 1133 4100 00000 2034</span>
                                    <span style={{"color":"red"}}>Intesa Sanpaolo - BIC: BCITITMM</span>
                                </span>
                            </div>
                            <div class="d-flex flex-column flex-root align-items-md-end">
                        <h1 class="display-5 font-weight-boldest mb-10"><span style={{color: 'red'}}>{ordineRiparazione ? 'ASSISTENZA ' : null}</span>COMMISSIONE N. {numero}</h1>
                                <span class="font-weight-bolder mb-2"><span class="mr-2">DATA COMMISSIONE: </span>
                                <span class=""><Moment format='DD/MM/YYYY'>{dataConfermaPreventivo}</Moment></span> </span>
                                <span class="font-weight-bolder mb-2"><span class="mr-2">FATTURA</span>
                                <span class="">{fattura ? "Si" : 'No'}</span></span>
                                <h2 className="nominativo_allineato">{cognome} {nome}</h2>
                            </div>
                        </div>
                        <div class="border-bottom w-100"></div>
                        <div class="d-flex justify-content-between pt-6 colonna-mobile mb-5">
                        <div class="d-flex flex-column flex-root">
                                <span class="font-weight-bolder mb-2">NOMINATIVO</span>
                                <div>Cognome Nome: <span className="text-nome-cognome">{cognome} {nome}</span></div>
                                {pIvaCodiceFiscale ? 
                                    <span class="">P.Iva/C.F: {pIvaCodiceFiscale}</span>
                                : null }
                                <div>Tel 1: <span className="text-nome-cognome">{telefono_1}</span></div>
                                {telefono_2 ? 
                                <div>Tel 2: <span className="text-nome-cognome">{telefono_2}</span></div>
                                : null }
                                {telefono_3 ? 
                                <div>Tel 3: <span className="text-nome-cognome">{telefono_3}</span></div>
                                : null }
                                {telefono_4 ? 
                                <div>Tel 4: <span className="text-nome-cognome">{telefono_4}</span></div>
                                : null }
                                {email_1 ? 
                                    <span class="">Email 1: {email_1}</span>
                                : null }
                                {email_2 ? 
                                    <span class="">Email 2: {email_2}</span>
                                : null }
                                {indirizzo || cap || citta ? 
                                <span class="">Indirizzo: {indirizzo} {cap}, {citta}</span>
                                : null }
                            </div>
                            <div class="d-flex flex-column flex-root">
                                <span class="font-weight-bolder mb-2">INDIRIZZO CONSEGNA</span>
                                {indirizzoPreventivo || capPreventivo || cittaPreventivo ? 
                                    <span class="">Indirizzo: {indirizzoPreventivo}  {capPreventivo}, {cittaPreventivo}</span>
                                : null }
                                {pianoPreventivo ? 
                                    <span class="">Piano: {pianoPreventivo}</span>
                                : null }
                                {scalaPreventivo ? 
                                    <span class="">Scala: {scalaPreventivo}</span>
                                : null }
                                    <span class="">ZTL: {ztlPreventivo ? 'Si' : 'No'}</span>
                                    <span class="">Ascensore: {ascensorePreventivo ? 'Si' : 'No'}</span>
                            </div>
                        </div>
                        <div class="d-flex justify-content-between pt-6">
                            <div class="d-flex flex-column flex-root">
                            <span class="font-weight-bolder mb-2"><span class="mr-2">MISURE</span>
                            <span class="">{misure === 'cliente'  ? "Comunicate dall'acquirente" : 'A carico del venditore'}</span></span>
                        </div>
                        <div class="d-flex flex-column flex-root">
                        </div>
                        </div>
                    </div>
                </div><div class="row justify-content-center py-8 px-8 py-md-10 px-md-0">
                    <div class="col-md-10">
                        <div class="table-responsive">
                            <table class="table">
                                <thead>
                                    <tr>
                                    <th class="text-left pr-0 font-weight-bold  text-uppercase">Descrizione generale</th>
                                    </tr>
                                </thead>
                                <tbody>
                                <tr class="font-weight-boldest">
                                    <td class=" pt-7 d-flex ">
                                        {descrizioneGenerale}
                                    </td>
                                </tr> 
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div class="row justify-content-center  px-8  px-md-0">
                    <div class="col-md-10">
                        <div class="table-responsive">
                            <table class="table">
                            {abilitaVista ? createUIElementiSemplificata() : createUIElementi()}
                            </table>
                        </div>
                    </div>
                </div>
                <div class="row justify-content-center bg-gray-100 px-8 px-md-0 mx-0">
                <div class="mt-10 col-md-10">
                <div class="table-responsive">
                            <table class="table">
                                <thead>
                                    <tr>
                                        <th class="font-weight-bold   text-uppercase">NOTE PREVENTIVO</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr class="font-weight-bolder">
                                        <td>{notePreventivo}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div class="row justify-content-center bg-gray-100 py-8 px-8 py-md-10 px-md-0 mx-0 pagina-immagine">
                <div class="mt-10 col-md-10">
                        <div class={`table-responsive ${ordineRiparazione ? 'noprint': null}`}>
                            <table class="table">
                            <tbody>
                                    <tr>
                                        <td class="text-left">Consulenza, progetto/i (di proprietà AR-CA arredamenti fino a stipula del contratto), preventivo/i</td>
                                        <td class={`text-right pr-0 ${costoProgetto.conteggiato ? '' : "prezzo-non-contato"}`}>{trasformaCifra(costoProgetto.prezzo)}€</td>
                                    </tr>
                                    <tr>
                                        <td class="text-left">Rilevo misure, disegni per architettonico, disegno per impianti, verifica esecuzione lavori</td>
                                        <td class={`text-right pr-0 ${costoMisure.conteggiato ? '' : "prezzo-non-contato"}`}>{trasformaCifra(costoMisure.prezzo)}€</td>
                                    </tr>
                                    <tr>
                                        <td class="text-left">Servizi di magazzinaggio, carico, trasporto, scarico al piano, sballaggio, montaggio, smaltimento imballi</td>
                                        <td class={`text-right pr-0 ${costoMagazzino.conteggiato ? '' : "prezzo-non-contato"}`}>{trasformaCifra(costoMagazzino.prezzo)}€</td>
                                    </tr>
                                    <tr>
                                        <td class="text-left">Eventuali servizi per smontaggio, smaltimento, riposizionamento, modifiche, trasloco, montaggio mobili/elettrodomestici del cliente (30,00€ per operaio)</td>
                                        {!costoSmontaggio.conteggiato && (costoSmontaggio.prezzo == 0 || !costoSmontaggio.prezzo) ?
                                            <td class="text-right pr-0 ">da definire</td>
                                            :
                                            <td class={`text-right pr-0 ${costoSmontaggio.conteggiato ? '' : "prezzo-non-contato"}`}>{trasformaCifra(costoSmontaggio.prezzo)}€</td>
                                        }
                                    </tr>
                                    <tr>
                                        <td class="text-left">Allacciamenti elettrici (montaggio lampade)</td>
                                        {!costoElettricista.conteggiato && (costoElettricista.prezzo == 0 || !costoElettricista.prezzo) ?
                                            <td class="text-right pr-0 ">da definire</td>
                                            :
                                            <td class={`text-right pr-0 ${costoElettricista.conteggiato ? '' : "prezzo-non-contato"}`}>{trasformaCifra(costoElettricista.prezzo)}€</td>
                                        }
                                    </tr>
                                    <tr>
                                        <td class="text-left">Allacciamenti idraulici (acqua e gas)</td>
                                        {!costoIdraulico.conteggiato && (costoIdraulico.prezzo == 0 || !costoIdraulico.prezzo) ?
                                            <td class="text-right pr-0 ">da definire</td>
                                            :
                                            <td class={`text-right pr-0 ${costoIdraulico.conteggiato ? '' : "prezzo-non-contato"}`}>{trasformaCifra(costoIdraulico.prezzo)}€</td>
                                        }
                                    </tr>
                                    <tr>
                                        <td class="text-left">Eventuale servizio per occupazione suolo pubblico ed elevatore o facchinaggio</td>
                                        {!costoElevatore.conteggiato && (costoElevatore.prezzo == 0 || !costoElevatore.prezzo) ?
                                            <td class="text-right pr-0 ">da definire</td>
                                            :
                                            <td class={`text-right pr-0 ${costoElevatore.conteggiato ? '' : "prezzo-non-contato"}`}>{trasformaCifra(costoElevatore.prezzo)}€</td>
                                        }
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    <div class={`table-responsive ${ordineRiparazione ? 'noprint': null}`}>
                        <table class="table">
                            <tbody>
                                <tr>
                                    <td colspan="4" class="border-0"></td>
                                    <td class="border-0 pt-0 pb-0 font-weight-bolder font-size-h5 text-right colore-totale">TOTALE COMMISSIONE (IVA INCL.)</td>
                                    <td class="border-0 pt-0 pb-0 font-weight-bolder font-size-h5 text-right pr-0 colore-totale">{trasformaCifra(totale)}€</td>
                                </tr>
                                <tr>
                                    <td colspan="4" class="border-0"></td>
                                    <td class={`border-0 font-weight-bolder text-right ${statoPagamento === 'acconto' || statoPagamento === "si"  ? "colore-caparra-pagata": "colore-caparra"}`}>CAPARRA CONFIRMATORIA</td>
                                    <td class={`border-0 font-weight-bolder text-right pr-0 ${statoPagamento === 'acconto' || statoPagamento === "si"  ? "colore-caparra-pagata": "colore-caparra"}`}>{trasformaCifra(acconto)}€</td>
                                </tr>
                                {accontiPersonalizzati.length > 0 ? parseFloat(accontiPersonalizzati[0].cifraAcconto) > 0 ?
                                    <tr>
                                        <td colspan="4" class="border-0"></td>
                                        <td class={`border-0 pt-0 font-weight-bolder text-right ${statoPagamento === 'acconto' || statoPagamento === "si"  ? "colore-caparra-pagata": "colore-caparra"}`}>{accontiPersonalizzati[0].nomeAcconto.toUpperCase()}</td>
                                        <td class={`border-0 pt-0 font-weight-bolder text-right pr-0 ${statoPagamento === 'acconto' || statoPagamento === "si"  ? "colore-caparra-pagata": "colore-caparra"}`}>{trasformaCifra(accontiPersonalizzati[0].cifraAcconto)}€</td>
                                    </tr>
                                    : null
                                : null}
                                    {accontiPersonalizzati.length > 1 ? parseFloat(accontiPersonalizzati[1].cifraAcconto) > 0 ?
                                    <tr>
                                        <td colspan="4" class="border-0"></td>
                                        <td class={`border-0 pt-0 font-weight-bolder text-right ${statoPagamento === 'acconto' || statoPagamento === "si"  ? "colore-caparra-pagata": "colore-caparra"}`}>{accontiPersonalizzati[1].nomeAcconto.toUpperCase()}</td>
                                        <td class={`border-0 pt-0 font-weight-bolder text-right pr-0 ${statoPagamento === 'acconto' || statoPagamento === "si"  ? "colore-caparra-pagata": "colore-caparra"}`}>{trasformaCifra(accontiPersonalizzati[1].cifraAcconto)}€</td>
                                    </tr>
                                    : null
                                    : null}
                                </tbody>
                                    </table>
                                    <table class="table">
                                        <tbody>
                                            <tr>
                                                <td class={`border-0 pt-0 font-weight-bolder text-right ${statoPagamento === 'si' ? "colore-totale-pagato" : "colore-totale"}`}>
                                                    <span class="noprint float-right">SALDO ALL’ARRIVO MERCE NOSTRI MAGAZZINI</span>
                                                    <div class="wrap_saldo">
                                                        <div class="firma_saldo div-hide print">
                                                            <div class="rect_saldo-wrap">
                                                                <span class="rect_saldo"></span>
                                                                <div class="firma-saldo">.........................................</div>
                                                            </div>
                                                        </div>
                                                        <span class="div-hide print">SALDO ALL’ARRIVO MERCE NOSTRI MAGAZZINI</span>
                                                    </div></td>
                                                <td class={`border-0 pt-0 font-weight-bolder text-right pr-0 tot2 ${statoPagamento === 'si' ? "colore-totale-pagato" : "colore-totale"}`}>{trasformaCifra(saldo)}€</td>
                                            </tr>
                                        </tbody>
                                    </table>
                        </div>
                        <div class="table-responsive">
                            <table class="table">
                                <thead>
                                    <tr>
                                        <th class="font-weight-bold   text-uppercase">NOTE PAGAMENTO</th>
                                    </tr>
                                </thead>
                                <tbody>
                                <div class="form-group row">
                                                    <div class="col-lg-6">
                                                        <label>Note Pagamento:</label>
                                                        <textarea type="text" class="form-control" placeholder="Inserisci note pagamento" name="notePagamento" value={notePagamento} onChange={e => onChangesetDatiOrdine(e)} />
                                                    </div>
                                                    <div class="col-lg-2">
                                                    </div>
                                                    <div class="col-lg-2">
                                                    </div>
                                                    <div class="col-lg-2">
                                                    </div>
                                                </div>
                                </tbody>
                            </table>
                        </div>
                        <div class="table-responsive">
                            <table class="table">
                                <thead>
                                    <tr>
                                        <th class="font-weight-bold   text-uppercase">PREVISIONE CONSEGNA INDICATIVA</th>
                                        <th class="font-weight-bold   text-uppercase">NOTE CONSEGNA</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr class="font-weight-bolder">
                                    <td className="print div-hide">
                                            <label><Moment format='DD/MM/YYYY'>{dataConsegnaStart}</Moment></label><br />
                                        </td>
                                        <td className="noprint">
                                            <Moment format='DD/MM/YYYY'>{dataConsegnaStart}</Moment> {dataConsegnaStart.getHours() < 12 ? '(mattino)' : '(pomeriggio)'}<br />
                                            <label>Montatore: {cognomeMontatore} - {nomeMontatore}</label>
                                        </td>
                                        <td className="">
                                            <label>{note}</label><br />
                                    </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                {ordineRiparazione ? null :
               <div class={`row justify-content-center bg-gray-100 mx-0 ${metodoPagamento ? null : "noprint"}`}>
               <div class="col-md-10">
                   <div class="table-responsive">
                       <table class="table">
                           <thead>
                               <tr>
                                   <th class="font-weight-bold   text-uppercase">METODO DI PAGAMENTO</th>
                                   <th class="font-weight-bold   text-uppercase noprint">STATO PAGAMENTO</th>
                               </tr>
                           </thead>
                           <tbody>
                               <tr class="font-weight-bolder">
                                   <td className="print div-hide">
                                       {metodoPagamento}
                                   </td>
                                   <td className="noprint">
                                       {user.role === 'magazziniere' ?
                                           metodoPagamento
                                           :
                                           <input type="text" class="form-control mt-3 noprint" name="metodoPagamento" value={metodoPagamento} placeholder="Inserisci metodo di pagamento" onChange={e => setDatiCliente({ ...datiCliente, metodoPagamento: e.target.value })} />
                                       }
                                   </td>
                                   <td className="noprint">
                                       {user.role === 'magazziniere' ?
                                           statoPagamento === 'si' ? 'Saldo pagato' : statoPagamento === 'acconto' ? 'Caparra pagata' : statoPagamento === accontiPersonalizzati[0].nomeAcconto ? accontiPersonalizzati[0].nomeAcconto : statoPagamento === accontiPersonalizzati[1].nomeAcconto ? accontiPersonalizzati[1].nomeAcconto : 'Non pagato'
                                           :
                                           <select class="form-control mt-3" name="statoPagamento" onChange={e => setDatiCliente({ ...datiCliente, statoPagamento: e.target.value })}>
                                               <option selected={statoPagamento === 'no' ? true : false} value={'no'}>Non pagato</option>
                                               <option selected={statoPagamento === 'acconto' ? true : false} value={'acconto'}>Caparra pagata</option>
                                               {accontiPersonalizzati.length > 0 && parseFloat(accontiPersonalizzati[0].cifraAcconto) > 0 ?
                                                   <option selected={statoPagamento === accontiPersonalizzati[0].nomeAcconto ? true : false} value={accontiPersonalizzati[0].nomeAcconto}>{accontiPersonalizzati[0].nomeAcconto}</option>
                                                   : null}
                                               {accontiPersonalizzati.length > 1 && parseFloat(accontiPersonalizzati[1].cifraAcconto) > 0 ?
                                                   <option selected={statoPagamento === accontiPersonalizzati[1].nomeAcconto ? true : false} value={accontiPersonalizzati[1].nomeAcconto}>{accontiPersonalizzati[1].nomeAcconto}</option>
                                                   : null}
                                               <option selected={statoPagamento === 'si' ? true : false} value={'si'}>Saldo pagato</option>
                                           </select>
                                       }
                                   </td>
                               </tr>
                           </tbody>
                       </table>
                   </div>
               </div>
           </div>
                }
                <div class="row justify-content-center print div-hide">
                    <div class="col-md-6">
                        <div class="example">
                            <p>Il Venditore AR-CA Arredamenti srl &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</p>
                            <div class="example-preview">
                                <div class="mt-20">
                                    <div class="separator separator-dashed separator-border-2 mb-2"></div>
            
                                    </div>
        
        
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="example">
                                <p>Il Committente, <Moment format='DD/MM/YYYY'>{new Date()}</Moment> Pavia &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</p>
                                <div class="example-preview">
                                    <div class="mt-20">
                                        <div class="separator separator-dashed separator-border-2 mb-2"></div>
            
                                    </div>
        
                                </div>
    
                        </div>
                    </div>
                </div>
                {ordine.allegati.length > 0 ?
                    <div class="row justify-content-center py-8 px-8 py-md-10 px-md-0 noprint">
                        <div class="col-md-10">
                        {ordine.allegati.findIndex(x => x.tipologia === 'disegno') === -1 ? null :
                            <div class="d-flex flex-column flex-root">
                                <span class="font-weight-bolder mb-2">Allegati disegno:</span>
                                <div class="d-flex mb-8">
                                    {ordine.allegati.map((allegato) => (
                                         allegato.tipologia === 'disegno' ?
                                        allegato.type === 'image/jpeg' || allegato.type === 'image/png' ?
                                            <div class="symbol symbol-70 flex-shrink-0 mr-4 bg-light">
                                                <a href={allegato.url} target="_blank">
                                                    <div class="symbol-label" style={{ backgroundImage: "url(" + allegato.url + ")" }}>
                                                        <span class="svg-icon svg-icon-lg mx-2"><i class="far fa-image"></i></span>
                                                    </div>
                                                    <div className="nome-pdf">
                                                <p style={{"margin-bottom": "0px !important"}}>{allegato.nomeOriginale}</p>
                                            </div>
                                                </a>
                                            </div>
                                            :
                                            <div class="symbol symbol-70 flex-shrink-0">
                                                <a href={allegato.url} target="_blank" class="h-70px w-70px btn btn-light-primary d-flex flex-column flex-center font-weight-bolder p-0">
                                                    <span class="svg-icon svg-icon-lg m-0"><i class="fas fa-file-pdf"></i></span>                        PDF
                                        </a>
                                            </div>
                                            : null
                                    ))}
                                </div>
                            </div>
                            }
                            {ordine.allegati.findIndex(x => x.tipologia === 'confermaordine') === -1 ? null :
                            <div class="d-flex flex-column flex-root">
                                <span class="font-weight-bolder mb-2">Allegati conferme d'ordine:</span>
                                <div class="d-flex mb-8">
                                    {ordine.allegati.map((allegato) => (
                                        allegato.tipologia === 'confermaordine' ?
                                        allegato.type === 'image/jpeg' || allegato.type === 'image/png' ?
                                            <div class="symbol symbol-70 flex-shrink-0 mr-4 bg-light">
                                                <a href={allegato.url} target="_blank">
                                                    <div class="symbol-label" style={{ backgroundImage: "url(" + allegato.url + ")" }}>
                                                        <span class="svg-icon svg-icon-lg mx-2"><i class="far fa-image"></i></span>
                                                    </div>
                                                    <div className="nome-pdf">
                                                <p style={{"margin-bottom": "0px !important"}}>{allegato.nomeOriginale}</p>
                                            </div>
                                                </a>
                                            </div>
                                            :
                                            <div class="symbol symbol-70 flex-shrink-0">
                                                <a href={allegato.url} target="_blank" class="h-70px w-70px btn btn-light-primary d-flex flex-column flex-center font-weight-bolder p-0">
                                                    <span class="svg-icon svg-icon-lg m-0"><i class="fas fa-file-pdf"></i></span>                        PDF
                                        </a>
                                            </div>
                                            : null
                                    ))}
                                </div>
                            </div>
                        }
                        {ordine.allegati.findIndex(x => x.tipologia === 'ordinefornitore') === -1 ? null :
                            <div class="d-flex flex-column flex-root">
                                <span class="font-weight-bolder mb-2">Allegati ordini fornitore:</span>
                                <div class="d-flex mb-8">
                                    {ordine.allegati.map((allegato) => (
                                         allegato.tipologia === 'ordinefornitore' ?
                                        allegato.type === 'image/jpeg' || allegato.type === 'image/png' ?
                                            <div class="symbol symbol-70 flex-shrink-0 mr-4 bg-light">
                                                <a href={allegato.url} target="_blank">
                                                    <div class="symbol-label" style={{ backgroundImage: "url(" + allegato.url + ")" }}>
                                                        <span class="svg-icon svg-icon-lg mx-2"><i class="far fa-image"></i></span>
                                                    </div>
                                                    <div className="nome-pdf">
                                                <p style={{"margin-bottom": "0px !important"}}>{allegato.nomeOriginale}</p>
                                            </div>
                                                </a>
                                            </div>
                                            :
                                            <div>
                                            <div class="symbol symbol-70 flex-shrink-0">
                                                <a href={allegato.url} target="_blank" class="h-70px w-70px btn btn-light-primary d-flex flex-column flex-center font-weight-bolder p-0">
                                                    <span class="svg-icon svg-icon-lg m-0"><i class="fas fa-file-pdf"></i></span>                        PDF
                                        </a>
                                            </div>
                                            <div className="nome-pdf">
                                                <p style={{"margin-bottom": "0px !important"}}>{allegato.nomeOriginale}</p>
                                            </div>
                                            </div>
                                            : null
                                    ))}
                                </div>
                            </div>
                            }
                            {ordine.allegati.findIndex(x => x.tipologia === 'misure') === -1 ? null :
                            <div class="d-flex flex-column flex-root">
                                <span class="font-weight-bolder mb-2">Allegati rilievo misure:</span>
                                <div class="d-flex mb-8">
                                    {ordine.allegati.map((allegato) => (
                                         allegato.tipologia === 'misure' ?
                                        allegato.type === 'image/jpeg' || allegato.type === 'image/png' ?
                                            <div class="symbol symbol-70 flex-shrink-0 mr-4 bg-light">
                                                <a href={allegato.url} target="_blank">
                                                    <div class="symbol-label" style={{ backgroundImage: "url(" + allegato.url + ")" }}>
                                                        <span class="svg-icon svg-icon-lg mx-2"><i class="far fa-image"></i></span>
                                                    </div>
                                                    <div className="nome-pdf">
                                                <p style={{"margin-bottom": "0px !important"}}>{allegato.nomeOriginale}</p>
                                            </div>
                                                </a>
                                            </div>
                                            :
                                            <div>
                                            <div class="symbol symbol-70 flex-shrink-0">
                                                <a href={allegato.url} target="_blank" class="h-70px w-70px btn btn-light-primary d-flex flex-column flex-center font-weight-bolder p-0">
                                                    <span class="svg-icon svg-icon-lg m-0"><i class="fas fa-file-pdf"></i></span>                        PDF
                                        </a>
                                            </div>
                                            <div className="nome-pdf">
                                                <p style={{"margin-bottom": "0px !important"}}>{allegato.nomeOriginale}</p>
                                            </div>
                                            </div>
                                            : null
                                    ))}
                                </div>
                            </div>
                            }
                        </div>
                    </div>
                    : null}
                    <div class="card-body noprint">
                        <div class="option_stampa col-lg-12">
                            <label class="checkbox">
                                <input type="checkbox" checked={abilitaVista} onChange={e => onChangeSetVistaStampa(e)} />
                                    <span></span> 
                                    Vista semplice (non visualizza magazzino)
                            </label>
                        </div>
                    </div>
                <div class="row justify-content-center py-8 px-8 py-md-10 px-md-0 noprint">
                    <div class="col-md-10">
                        <div class="d-flex justify-content-between">
                            <button class='btn btn-secondary font-weight-bold' onClick={() => onClickChiudiOrdine(false)}>Indietro</button>
                            {user.role === 'magazziniere' ? null :
                            <Fragment>
                                <button class="btn btn-info font-weight-bold" onClick={() => window.print()}>Stampa</button>
                                <button class="btn btn-info font-weight-bold" onClick={() => openDocumenti()}>Stampa Dati</button>
                            </Fragment>
                            } 
                            {ordineRiparazione ? null :
                                <button class='btn btn-warning font-weight-bold' onClick={() => setCorrezioneOpen(true)}>Aggiungi riparazione</button>
                            }
                            {user.role === 'admin' ? 
                                <>
                                <button class="btn btn-warning font-weight-bold" onClick={() => onClickOpenModificaOrdine()}>Modifica Ordine</button>
                                <button class="btn btn-warning font-weight-bold" onClick={() => onClickAnnullaOrdine(idOrdine)}>Annulla Consegna</button>
                                </>
                                :
                                ''
                            }
                            {user.role === 'admin' ? 
                                <button class='btn btn-primary font-weight-bold' onClick={() => onClickSegnaComeSaldato({idOrdine})}>Salva</button>
                                :
                                ''
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="print div-hide pagina-immagine ">
        <img  src={condizioniGenerali} width="97%" height="auto"/>
        </div>
        </Fragment>
    )
}

OrdineConsegnato.protoTypes = {
    auth: PropTypes.object.isRequired,
    modificaPagamento: PropTypes.func.isRequired,
    annullaConsegnaOrdine: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth
});

export default connect(mapStateToProps, { modificaPagamento, annullaConsegnaOrdine })(OrdineConsegnato);

