import React, { useEffect, useState, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Moment from 'react-moment';
import DatePicker, { registerLocale } from "react-datepicker";
import setHours from "date-fns/setHours";
import setMinutes from "date-fns/setMinutes";
import "react-datepicker/dist/react-datepicker.css";
import it from 'date-fns/locale/it';
import Spinner from '../../layout/Spinner';
import { switchSidebarLogo, trasformaCifra, getDcumentoPrint } from '../../../utility/Utility';
import { setDataConsegnaOrdine, setOrdineConsegnato, uploadAllegatiOrdine, uploadNuovoAllegato, eliminaAllegato, modificaPagamentoNote, modificaMagazzino, annullaConsegnaOrdine } from '../../../actions/ordine';
import { getMontatori } from '../../../actions/montatore';
import condizioniGenerali from '../../../img/Condizionigenerali.jpg'
import ModificaOrdineConfermato from './ModificaOrdineConfermato'
registerLocale('it', it)
const OrdineConsegna = ({ auth: { user }, ordine, allegati, montatore: { montatori, loading }, getMontatori, setDataConsegnaOrdine, setOrdineConsegnato, uploadAllegatiOrdine, uploadNuovoAllegato, eliminaAllegato, onClickChiudiOrdine, modificaPagamentoNote, modificaMagazzino, annullaConsegnaOrdine }) => {

    useEffect(() => {
        getMontatori();
    }, [getMontatori]);

    const [openModificaOrdine, setOpenModificaOrdine] = useState(false);

    const [datiCliente, setDatiCliente] = useState({
        idOrdine: '',
        pIvaCodiceFiscale: '',
        nome: '',
        cognome: '',
        telefono_1: '',
        telefono_2: '',
        telefono_3: '',
        telefono_4: '',
        email_1: '',
        email_2: '',
        indirizzo: '',
        cap: '',
        citta: '',
        indirizzoPreventivo: '',
        capPreventivo: '',
        cittaPreventivo: '',
        pianoPreventivo: '',
        scalaPreventivo: '',
        ascensorePreventivo: '',
        ztlPreventivo: '',
        nomePuntoVendita: '',
        indirizzoPuntoVendita: '',
        telefonoPuntoVendita: '',
        emailPuntoVendita: '',
        sitoWebPuntoVendita: '',
        emailVenditore: '',
        nomeVenditore: '',
        cognomeVenditore: '',
        numero: '',
        date: '',
        stato: '',
        magazzino: '',
        misure: '',
        fattura: '',
        elementi: [],
        acconto: '',
        accontiPersonalizzati : [
            {
                nomeAcconto:'',
                cifraAcconto: null
            },{
                nomeAcconto:'',
                cifraAcconto: null
            }
        ],
        saldo: '',
        totale: '',
        dataConsegnaStart: new Date(),
        dataConsegnaEnd: new Date(),
        montatore: '',
        nomeMontatore: '',
        cognomeMontatore: '',
        ordineRiparazione: false,
        ordineRiparato: false,
        descrizioneGenerale: '',
        note: '',
        notePagamento: '',
        notePreventivo: '',
        metodoPagamento: '',
        statoPagamento: '',
        dataConfermaPreventivo: new Date(),
        costoProgetto: {
            prezzo: 0,
            conteggiato: false
        },
        costoMisure: {
            prezzo: 0,
            conteggiato: false
        },
        costoMagazzino: {
            prezzo: 0,
            conteggiato: false
        },
        costoSmontaggio: {
            prezzo: 0,
            conteggiato: false
        },
        costoElettricista: {
            prezzo: 0,
            conteggiato: false
        },
        costoIdraulico: {
            prezzo: 0,
            conteggiato: false
        },
        costoElevatore: {
            prezzo: 0,
            conteggiato: false
        },
        fileDocumenti: ''
    });


    useEffect(() => {
        setDatiCliente({
            idOrdine: !ordine ? '' : ordine._id,
            pIvaCodiceFiscale: !ordine ? '' : ordine.cliente.pIvaCodiceFiscale,
            nome: !ordine ? '' : ordine.cliente.nome,
            cognome: !ordine ? '' : ordine.cliente.cognome,
            telefono_1: !ordine ? '' : ordine.cliente.telefono_1,
            telefono_2: !ordine ? '' : ordine.cliente.telefono_2,
            telefono_3: !ordine ? '' : ordine.cliente.telefono_3,
            telefono_4: !ordine ? '' : ordine.cliente.telefono_4,
            email_1: !ordine ? '' : ordine.cliente.email_1,
            email_2: !ordine ? '' : ordine.cliente.email_2,
            indirizzo: !ordine ? '' : ordine.cliente.indirizzo,
            cap: !ordine ? '' : ordine.cliente.cap,
            citta: !ordine ? '' : ordine.cliente.citta,
            indirizzoPreventivo: !ordine ? '' : ordine.indirizzoPreventivo,
            capPreventivo: !ordine ? '' : ordine.capPreventivo,
            cittaPreventivo: !ordine ? '' : ordine.cittaPreventivo,
            pianoPreventivo: !ordine ? '' : ordine.pianoPreventivo,
            scalaPreventivo: !ordine ? '' : ordine.scalaPreventivo,
            ascensorePreventivo: !ordine ? '' : ordine.ascensorePreventivo,
            ztlPreventivo: !ordine ? '' : ordine.ztlPreventivo,
            nomePuntoVendita: !ordine ? '' : ordine.puntoVendita.nome,
            nomePuntoVenditaDb: !ordine ? '' : ordine.puntoVendita.nomeDb,
            indirizzoPuntoVendita: !ordine ? '' : ordine.puntoVendita.indirizzo,
            telefonoPuntoVendita: !ordine ? '' : ordine.puntoVendita.telefono,
            emailPuntoVendita: !ordine ? '' : ordine.puntoVendita.email,
            fileDocumenti: !ordine ? '' : ordine.venditore.fileDocumenti,
            sitoWebPuntoVendita: !ordine ? '' : ordine.puntoVendita.sitoWeb,
            nomeVenditore: !ordine ? '' : ordine.venditore.nome,
            cognomeVenditore: !ordine ? '' : ordine.venditore.cognome,
            emailVenditore: !ordine ? '' : ordine.venditore.email,
            numero: !ordine ? '' : ordine.numero,
            date: !ordine ? '' : ordine.date,
            stato: !ordine ? '' : ordine.stato,
            misure: !ordine ? '' : ordine.misure,
            fattura: !ordine ? '' : ordine.fattura,
            elementi: !ordine ? '' : ordine.elementi,
            acconto: !ordine ? '' : ordine.acconto,
            accontiPersonalizzati: !ordine || !ordine.accontiPersonalizzati || ordine.accontiPersonalizzati.length <= 0 ? [
                {
                    nomeAcconto: '',
                    cifraAcconto: 0
                }, {
                    nomeAcconto: '',
                    cifraAcconto: 0
                }
            ] : ordine.accontiPersonalizzati.length == 1 ? [
                {
                    nomeAcconto: ordine.accontiPersonalizzati[0].nomeAcconto,
                    cifraAcconto: ordine.accontiPersonalizzati[0].cifraAcconto
                }, {
                    nomeAcconto: '',
                    cifraAcconto: 0
                }
            ] : ordine.accontiPersonalizzati,
            saldo: !ordine ? '' : ordine.saldo,
            totale: !ordine ? '' : ordine.totale,
            dataConsegnaStart: !ordine || !ordine.dataConsegnaStart ? null : new Date(ordine.dataConsegnaStart),
            dataConsegnaEnd: !ordine || !ordine.dataConsegnaEnd ? null : new Date(ordine.dataConsegnaEnd),
            idMontatore: !ordine || !ordine.montatore ? '' : ordine.montatore._id,
            nomeMontatore: !ordine || !ordine.montatore ? '' : ordine.montatore.nome,
            cognomeMontatore: !ordine || !ordine.montatore ? '' : ordine.montatore.cognome,
            ordineRiparazione: !ordine ? false : ordine.ordineRiparazione,
            ordineRiparato: !ordine ? false : ordine.ordineRiparato,
            descrizioneGenerale: !ordine ? '' : ordine.descrizioneGenerale,
            note: !ordine ? '' : ordine.note,
            notePagamento: !ordine ? '' : ordine.notePagamento,
            notePreventivo: !ordine ? '' : ordine.notePreventivo,
            metodoPagamento: !ordine ? '' : ordine.metodoPagamento,
            statoPagamento: !ordine ? '' : ordine.statoPagamento,
            dataConfermaPreventivo: !ordine ||  ordine.dataConfermaPreventivo === undefined ? new Date() : new Date(ordine.dataConfermaPreventivo),
            costoProgetto: !ordine || !ordine.costoProgetto ? {
                prezzo: 0,
                conteggiato: false
            } : ordine.costoProgetto,
            costoMisure: !ordine || !ordine.costoMisure ? {
                prezzo: 0,
                conteggiato: false
            } : ordine.costoMisure,
            costoMagazzino: !ordine || !ordine.costoMagazzino ? {
                prezzo: 0,
                conteggiato: false
            } : ordine.costoMagazzino,
            costoSmontaggio: !ordine || !ordine.costoSmontaggio ? {
                prezzo: 0,
                conteggiato: false
            } : ordine.costoSmontaggio,
            costoElettricista: !ordine || !ordine.costoElettricista ? {
                prezzo: 0,
                conteggiato: false
            } : ordine.costoElettricista,
            costoIdraulico: !ordine || !ordine.costoIdraulico ? {
                prezzo: 0,
                conteggiato: false
            } : ordine.costoIdraulico,
            costoElevatore: !ordine || !ordine.costoElevatore ? {
                prezzo: 0,
                conteggiato: false
            } : ordine.costoElevatore
        });
        uploadAllegatiOrdine(ordine.allegati)
    }, [ordine, uploadAllegatiOrdine]);

    const { idOrdine, pIvaCodiceFiscale, nome, cognome, telefono_1, telefono_2, telefono_3, telefono_4, email_1, email_2, indirizzo, cap, citta, indirizzoPreventivo, capPreventivo, cittaPreventivo, pianoPreventivo, scalaPreventivo, ascensorePreventivo, ztlPreventivo, nomePuntoVendita, nomePuntoVenditaDb, indirizzoPuntoVendita, telefonoPuntoVendita, emailPuntoVendita, sitoWebPuntoVendita, nomeVenditore, cognomeVenditore, emailVenditore, numero, date, stato, misure, fattura, elementi, acconto, accontiPersonalizzati, saldo, totale, dataConsegnaStart, dataConsegnaEnd, idMontatore, nomeMontatore, cognomeMontatore, ordineRiparazione, ordineRiparato, descrizioneGenerale, note, notePagamento, notePreventivo, metodoPagamento, statoPagamento, dataConfermaPreventivo, costoProgetto, costoMisure, costoMagazzino, costoSmontaggio, costoElettricista, costoIdraulico, costoElevatore, fileDocumenti } = datiCliente;

    const [abilitaAccontiPersonalizzati, setAbilitaAccontiPersonalizzati] = useState(false);

    const [abilitaVista, setAbilitaVista] = useState(false);

    const onChangeSetVistaStampa = e => setAbilitaVista(e.target.checked);

    const createUIElementi = () => {
        return (
            <Fragment>
                <thead>
                    <tr>
                        <th class="text-left pr-0 font-weight-bold  text-uppercase">Fornitore</th>
                        <th class="pr-0 font-weight-bold   text-uppercase">Descrizione</th>
                        <th class={`text-right font-weight-bold  text-uppercase ${ordineRiparazione ? 'noprint' : null}`}>Prezzo</th>
                        <th class="text-right font-weight-bold  text-uppercase noprint">Stato mag.</th>
                        <th class="text-right font-weight-bold  text-uppercase noprint ">Numero colli</th>
                        <th class="font-weight-bold  text-uppercase  data-arrivo noprint">Data di arrivo</th>
                    </tr>
                </thead>
                <tbody>
                    {elementi.length > 0 ?
                        elementi.map((elemento, i) => (
                            <tr class="font-weight-boldest">
                                <td class="pt-7 align-middle">
                                    {elemento.marca}
                                </td>
                                <td class="pt-7 align-middle mag-desc">
                                    {elemento.descrizione}
                                </td>
                                <td class={`text-right pt-7 align-middle ${ordineRiparazione ? 'noprint' : null}`}>{trasformaCifra(elemento.prezzo)}€</td>
                                <td class="pt-7 text-right noprint align-middle">
                                    {elemento.magazzino === 'no' ?
                                        <span class="p-5 label label-lg font-weight-bold btn btn-danger label-inline">Non in magazzino</span>
                                        : elemento.magazzino === 'parziale' ?
                                            <span class="p-5 label label-lg font-weight-bold btn btn-warning label-inline">Parz. in magazzino</span>
                                            :
                                            <span class="p-5 label label-lg font-weight-bold btn btn-success label-inline">In magazzino</span>
                                    }
                                </td>
                                <td class="pt-7 align-middle noprint">
                                    <span className=" testo-allineato-destra">{elemento.colli}</span>
                                </td>
                                <td class="pt-7 align-middle noprint data-arrivo">
                                <span className=" testo-allineato-destra"> <Moment format='DD/MM/YYYY'>{elemento.dataArrivoMagazzino}</Moment></span>
                                </td>
                            </tr>

                        )) : null
                    }
                </tbody>
            </Fragment>
        )
    }
    const createUIElementiSemplificata = () => {
        return (
            <Fragment>
                <thead>
                    <tr>
                        <th class="text-left pr-0 font-weight-bold  text-uppercase">Fornitore</th>
                        <th class="pr-0 font-weight-bold   text-uppercase">Descrizione</th>
                        <th class={`text-right font-weight-bold  text-uppercase ${ordineRiparazione ? 'noprint' : null}`}>Prezzo Lisitno</th>
                        <th class={`text-right font-weight-bold  text-uppercase ${ordineRiparazione ? 'noprint' : null}`}>Prezzo Scontato</th>
                    </tr>
                </thead>
                <tbody>
                    {elementi.length > 0 ?
                        elementi.map((elemento, i) => (
                            <tr class="font-weight-boldest">
                                <td class="pt-7 align-middle">
                                    {elemento.marca}
                                </td>
                                <td class="pt-7 align-middle mag-desc">
                                    {elemento.descrizione}
                                </td>
                                <td class={`text-right pt-7 align-middle ${ordineRiparazione ? 'noprint' : null}`}>{trasformaCifra(elemento.prezzoListino)}€</td>
                                <td class={`text-right pt-7 align-middle ${ordineRiparazione ? 'noprint' : null}`}>{trasformaCifra(elemento.prezzo)}€</td>                                
                            </tr>

                        )) : null
                    }
                </tbody>
            </Fragment>
        )
    }

    const countNoteColli = () => {
        const index =  elementi.findIndex(elemento =>( elemento.magazzino === 'parziale'))
         return index === -1 ? false : true
     }

    const createUIElementiAdmin = () => {
        return (
            <Fragment>
                <thead>
                    <tr>
                        <th class="text-left pr-0 font-weight-bold  text-uppercase">Fornitore</th>
                        <th class="pr-0 font-weight-bold   text-uppercase">Descrizione</th>
                        <th class={`text-right font-weight-bold  text-uppercase ${ordineRiparazione ? 'noprint' : null}`}>Prezzo Lisitno</th>
                        <th class={`text-right font-weight-bold  text-uppercase ${ordineRiparazione ? 'noprint' : null}`}>Prezzo Scontato</th>
                        <th class="text-right font-weight-bold  text-uppercase ">Stato mag.</th>
                        <th class="text-right font-weight-bold  text-uppercase ">Numero colli</th>
                        {countNoteColli() ?
                            <th class="text-right font-weight-bold  text-uppercase ">Note colli</th>
                            :
                            ''
                        }
                        <th class="text-right font-weight-bold  text-uppercase ">Posizione</th>
                        <th class="font-weight-bold  text-uppercase  data-arrivo">Data di arrivo</th>
                    </tr>
                </thead>
                <tbody>
                    {elementi.length > 0 ?
                        elementi.map((elemento, i) => (
                            <tr class="font-weight-boldest">
                                <td class="pt-7 align-middle">
                                    {elemento.marca}
                                </td>
                                <td class="pt-7 align-middle mag-desc">
                                    {elemento.descrizione}
                                </td>
                                <td class={`text-right pt-7 align-middle ${ordineRiparazione ? 'noprint' : null}`}>{trasformaCifra(elemento.prezzoListino)}€</td>
                                <td class={`text-right pt-7 align-middle ${ordineRiparazione ? 'noprint' : null}`}>{trasformaCifra(elemento.prezzo)}€</td>
                                <td class="pt-7 align-middle null">
                                    <div className="noprint ">
                                    <select class={elemento.magazzino === 'no' ? "red-option form-control" : elemento.magazzino === 'parziale' ? "color: orange-option form-control" : "color: green-option form-control" } name="elemento" onChange={e => onChangeSetMagazzinoElementoOrdine(e, i)} >
                                        <option selected={elemento.magazzino === 'no' ? true : false} value={'no'}>No</option>
                                        <option selected={elemento.magazzino === 'parziale' ? true : false} value={'parziale'}>Parzialmente</option>
                                        <option selected={elemento.magazzino === 'si' ? true : false} value={'si'}>Si</option>
                                    </select>
                                    </div>
                                    <span class="hide-element print testo-allineato-destra">
                                        {elemento.magazzino}
                                    </span>
                                </td>
                                <td class="pt-7 align-middle null">
                                    <span className="noprint"><input  type="number" min="1" max="100" step="1" class="form-control" placeholder="Numero colli" name="prezzo" value={elemento.colli} onChange={e => onChangeSetColliElementoOrdine(e, i)} /></span>
                                    <span className="hide-element print testo-allineato-destra">{elemento.colli}</span>
                                </td>
                                {countNoteColli() ?
                                <>
                                    <td class="pt-7 align-middle null">
                                        <div className="noprint">
                                        <textarea type="text" class="form-control" name="note" value={elemento.note} placeholder="Note colli" onChange={e => onChangeSetNoteElementoOrdine(e, i)} />
                                        </div>
                                        <span class=" hide-element print">
                                            {elemento.note}
                                        </span>
                                    </td>
                                    </>
                                    :
                                    ''
                                }
                                <td class="pt-7 align-middle null">
                                    <div className="noprint">
                                    <input type="text" class="form-control" name="posizioneMagazzino" value={elemento.posizioneMagazzino} placeholder="Posizione" onChange={e => onChangeSetPosizioneMagazzinoElementoOrdine(e, i)} />
                                    </div>
                                    <span class="hide-element print">{elemento.posizioneMagazzino}</span>
                                </td>
                                <td class="pt-7 align-middle null data-arrivo">
                                    <DatePicker
                                        onChange={e => onChangeDataprevista(e, i)}
                                        dateFormat='dd/MM/yyyy'
                                        selected={elemento.dataArrivoMagazzino ? new Date(elemento.dataArrivoMagazzino) : new Date()}
                                        className="field w-input form-control"
                                        locale="it"
                                    />
                                </td>
                                
                            </tr>

                        )) : null
                    }
                </tbody>
            </Fragment>
        )
    }

    const onChangeSetColliElementoOrdine = (e, i) => {
        let data = [...datiCliente.elementi];
        data[i] = { ...data[i], colli: e.target.value };
        setDatiCliente({...datiCliente, elementi: data});   
    }

    const onChangeSetMagazzinoElementoOrdine = (e, i) => {
        let data = [...datiCliente.elementi];
        data[i] = { ...data[i], magazzino: e.target.value };
        setDatiCliente({...datiCliente, elementi: data});   
    }

    const onChangeDataprevista = (e, i) => {
        let data = [...datiCliente.elementi];
        data[i] = { ...data[i], dataArrivoMagazzino: e };
        setDatiCliente({...datiCliente, elementi: data});   
    }

    const onChangeSetNoteElementoOrdine = (e, i) => {
        let data = [...datiCliente.elementi];
        data[i] = { ...data[i], note: e.target.value };
        setDatiCliente({...datiCliente, elementi: data});   
    }

    const onChangeSetPosizioneMagazzinoElementoOrdine = (e, i) => {
        let data = [...datiCliente.elementi];
        data[i] = { ...data[i], posizioneMagazzino: e.target.value };
        setDatiCliente({...datiCliente, elementi: data});   
    }

    const [allegatoDisegno, setAllegatoDisegno] = useState();
    const processNuovoAllegatoDisegno = async() => {
        if(allegatoDisegno.files.length>0){
            const imageFile = allegatoDisegno.files[0];
            try {
                const file = imageFile;   
                const name = file.name.replace(/\s/g, '');
                const fileType = imageFile.type;                
                const part = Math.floor(Date.now() / 1000);
                const fileName = ""+part+"_"+name;
                uploadNuovoAllegato({ file, fileName, nomeOriginale: file.name, fileType, idOrdine, tipologia: 'disegno' });
              } catch (error) {
                console.log(error);
              }
        }
    }

    const [allegatoConfermaOrdine, setAllegatoConfermaOrdine] = useState();
    const processNuovoAllegatoConfermaOrdine = async() => {
        if(allegatoConfermaOrdine.files.length>0){
            const imageFile = allegatoConfermaOrdine.files[0];
            try {
                const file = imageFile;   
                const name = file.name.replace(/\s/g, '');
                const fileType = imageFile.type;                
                const part = Math.floor(Date.now() / 1000);
                const fileName = ""+part+"_"+name;
                uploadNuovoAllegato({ file, fileName, nomeOriginale: file.name, fileType, idOrdine, tipologia: 'confermaordine' });
              } catch (error) {
                console.log(error);
              }
        }
    }
 
    const [allegatoOrdineFornitore, setAllegatoOrdineFornitore] = useState();
    const processNuovoAllegatoOrdineFornitore = async() => {
        if(allegatoOrdineFornitore.files.length>0){
            const imageFile = allegatoOrdineFornitore.files[0];
            try {
                const file = imageFile;   
                const name = file.name.replace(/\s/g, '');
                const fileType = imageFile.type;                
                const part = Math.floor(Date.now() / 1000);
                const fileName = ""+part+"_"+name;
                uploadNuovoAllegato({ file, fileName, nomeOriginale: file.name, fileType, idOrdine, tipologia: 'ordinefornitore' });
              } catch (error) {
                console.log(error);
              }
        }
    }

    const [allegatoMisure, setAllegatoMisure] = useState();
    const processNuovoAllegatoMisure = async() => {
        if(allegatoMisure.files.length>0){
            const imageFile = allegatoMisure.files[0];
            try {
                const file = imageFile;   
                const name = file.name.replace(/\s/g, '');
                const fileType = imageFile.type;                
                const part = Math.floor(Date.now() / 1000);
                const fileName = ""+part+"_"+name;
                uploadNuovoAllegato({ file, fileName, nomeOriginale: file.name, fileType, idOrdine, tipologia: 'misure' });
              } catch (error) {
                console.log(error);
              }
        }
    }

    const onChangeDataConsegnaStart = e => {
        setDatiCliente({ ...datiCliente, dataConsegnaStart: e });
    }

    const onChangeDataConsegnaEnd = e => {
        setDatiCliente({ ...datiCliente, dataConsegnaEnd: e });
    }


    const onClickModificaPagamentoNote = () => {
        modificaPagamentoNote(idOrdine, metodoPagamento, statoPagamento, note, accontiPersonalizzati, saldo, totale);
    }

    const onClickModificaMagazzino = () => {
        modificaMagazzino(idOrdine, elementi);
    }

    const openDocumenti = () => {
        let objFra = document.createElement('iframe');     // Create an IFrame.
        objFra.style.visibility = 'hidden';   
        // Hide the frame.
        objFra.src = getDcumentoPrint(fileDocumenti);                   // Set source.

        document.body.appendChild(objFra);  // Add the frame to the web page.

        objFra.contentWindow.focus();       // Set focus.
        objFra.contentWindow.print(); 
    }

    const onClickEliminaAllegato = (_id, idOrdine) => {
        var result = window.confirm("Sei sicuro di voler eliminare l'allegato?");
        if (result) {
            eliminaAllegato(_id, idOrdine)
        }
    } 

    const onChangeSetNomeAccontiPersonalizzatiPreventivo = (e, i) => {
        var newAccontiPersonalizzati = accontiPersonalizzati
        newAccontiPersonalizzati[i].nomeAcconto = e.target.value;
        setDatiCliente({ ...datiCliente, accontiPersonalizzati: newAccontiPersonalizzati });
    }


    const onChangeSetCifraAccontiPersonalizzatiPreventivo = (e, i) => {
        var newAccontiPersonalizzati = accontiPersonalizzati
        newAccontiPersonalizzati.map(acconto => {
            acconto.cifraAcconto = isNaN(parseFloat(acconto.cifraAcconto)) ? 0 : parseFloat(acconto.cifraAcconto);
        });
        var somma = newAccontiPersonalizzati.map(acconto => acconto.cifraAcconto).reduce((a, b) => a + b);
        if (isNaN(parseFloat(e.target.value)) || !e.target.value) {
            newAccontiPersonalizzati[i].cifraAcconto = null;
            somma = newAccontiPersonalizzati.map(acconto => acconto.cifraAcconto).reduce((a, b) => a + b);
            setDatiCliente({ ...datiCliente, accontiPersonalizzati: accontiPersonalizzati, saldo: totale - acconto - somma });
        } else {
            if (totale - acconto - somma - parseFloat(e.target.value) + newAccontiPersonalizzati[i].cifraAcconto < 0) {
                newAccontiPersonalizzati[i].cifraAcconto = 0;
                somma = newAccontiPersonalizzati.map(acconto => acconto.cifraAcconto).reduce((a, b) => a + b);
                setDatiCliente({ ...datiCliente, accontiPersonalizzati: accontiPersonalizzati, saldo: totale - acconto - somma });
            } else {
                newAccontiPersonalizzati[i].cifraAcconto = parseFloat(e.target.value)
                somma = newAccontiPersonalizzati.map(acconto => acconto.cifraAcconto).reduce((a, b) => a + b);
                setDatiCliente({ ...datiCliente, accontiPersonalizzati: accontiPersonalizzati, saldo: (totale - acconto - somma).toFixed(2) });
            }
        }
    }

    const onClickAnnullaOrdine = (idOrdine) => {
        annullaConsegnaOrdine(idOrdine);
        //onClickChiudiOrdine(false)
    }

    const onClickOpenModificaOrdine = () => {
        setOpenModificaOrdine(true);
    }
    
    return (loading ? <Spinner /> :
    openModificaOrdine ?
            <ModificaOrdineConfermato
                ordine={ordine}
                setOpenModificaOrdine={setOpenModificaOrdine}
            />
            :
        <Fragment>
        <div className="card card-custom card-shadowless rounded-top-0" id="preventivo">
            <div class="card-body p-0">
                <iframe id="ifmcontentstoprint" style={{ height: "0px", width: "0px", position: "absolute" }}>
                </iframe>
                <div class="row justify-content-center py-8 px-8 py-md-10 px-md-0">
                    <div class="col-md-10">
                    <div class="d-flex justify-content-between pb-10 pb-md-20 flex-column flex-md-row print-orizzontale">
                    <div class="d-flex flex-column px-0 flex-root logo-mobile">
                                <div class="logo-stampa">
                                    <img alt="Logo" src={switchSidebarLogo(nomePuntoVenditaDb, user.role, 'grande')} className="max-h-150px" />
                                </div>
                                <h1>{nomePuntoVendita}</h1>
                                <span class=" d-flex flex-column">
                                    <span>{indirizzoPuntoVendita}</span>
                                    <span>{telefonoPuntoVendita}</span>
                                    <span>Ufficio consegne: 0382 573181</span>
                                    <div>Arredatore: <span className="text-nome-cognome">{nomeVenditore} {cognomeVenditore}</span><span> - {emailVenditore}</span></div>
                                    <span>{sitoWebPuntoVendita}</span>
                                    {nomePuntoVenditaDb !== 'arca_arredamenti' ?
                                        <span style={{"color":"red"}}>Instestato a: ARCA ARREDAMENTI CASA SRL</span>
                                    : 
                                        ''
                                    }  
                                    <span style={{"color":"red"}}>Intesa Sanpaolo - IBAN: IT 82O0 3069 1133 4100 00000 2034</span>
                                    <span style={{"color":"red"}}>Intesa Sanpaolo - BIC: BCITITMM</span>
                                </span>
                            </div>
                            <div class="d-flex flex-column flex-root align-items-md-end">
                        <h1 class="display-5 font-weight-boldest mb-10"><span style={{color: 'red'}}>{ordineRiparazione ? 'ASSISTENZA ' : null}</span>COMMISSIONE N. {numero}</h1>
                                <span class="font-weight-bolder mb-2"><span class="mr-2">DATA COMMISSIONE: </span>
                                <span class=""><Moment format='DD/MM/YYYY'>{dataConfermaPreventivo}</Moment></span> </span>
                                <span class="font-weight-bolder mb-2"><span class="mr-2">FATTURA</span>
                                <span class="">{fattura ? "Si" : 'No'}</span></span>
                                <h2 className="nominativo_allineato">{cognome} {nome}</h2>
                            </div>
                        </div>
                        <div class="border-bottom w-100"></div>
                        <div class="d-flex justify-content-between pt-6 colonna-mobile mb-5">
                        <div class="d-flex flex-column flex-root">
                                <span class="font-weight-bolder mb-2">NOMINATIVO</span>
                                <div>Cognome Nome: <span className="text-nome-cognome">{cognome} {nome}</span></div>
                                {pIvaCodiceFiscale ? 
                                    <span class="">P.Iva/C.F: {pIvaCodiceFiscale}</span>
                                : null }
                                <div>Tel 1: <span className="text-nome-cognome">{telefono_1}</span></div>
                                {telefono_2 ? 
                                <div>Tel 2: <span className="text-nome-cognome">{telefono_2}</span></div>
                                : null }
                                {telefono_3 ? 
                                <div>Tel 3: <span className="text-nome-cognome">{telefono_3}</span></div>
                                : null }
                                {telefono_4 ? 
                                <div>Tel 4: <span className="text-nome-cognome">{telefono_4}</span></div>
                                : null }
                                {email_1 ? 
                                    <span class="">Email 1: {email_1}</span>
                                : null }
                                {email_2 ? 
                                    <span class="">Email 2: {email_2}</span>
                                : null }
                                {indirizzo || cap || citta ? 
                                <span class="">Indirizzo: {indirizzo} {cap}, {citta}</span>
                                : null }
                            </div>
                            <div class="d-flex flex-column flex-root">
                                <span class="font-weight-bolder mb-2">INDIRIZZO CONSEGNA</span>
                                {indirizzoPreventivo || capPreventivo || cittaPreventivo ? 
                                    <span class="">Indirizzo: {indirizzoPreventivo}  {capPreventivo}, {cittaPreventivo}</span>
                                : null }
                                {pianoPreventivo ? 
                                    <span class="">Piano: {pianoPreventivo}</span>
                                : null }
                                {scalaPreventivo ? 
                                    <span class="">Scala: {scalaPreventivo}</span>
                                : null }
                                    <span class="">ZTL: {ztlPreventivo ? 'Si' : 'No'}</span>
                                    <span class="">Ascensore: {ascensorePreventivo ? 'Si' : 'No'}</span>
                            </div>
                        </div>
                        <div class="d-flex justify-content-between pt-6">
                            <div class="d-flex flex-column flex-root">
                            <span class="font-weight-bolder mb-2"><span class="mr-2">MISURE</span>
                            <span class="">{misure === 'cliente'  ? "Comunicate dall'acquirente" : 'A carico del venditore'}</span></span>
                        </div>
                        <div class="d-flex flex-column flex-root">
                        </div>
                        </div>
                    </div>
                </div>
                <div class="row justify-content-center  px-8  px-md-0">
                    <div class="col-md-10">
                        <div class="table-responsive">
                            <table class="table">
                                <thead>
                                    <tr>
                                    <th class="text-left pr-0 font-weight-bold  text-uppercase">Descrizione generale</th>
                                    </tr>
                                </thead>
                                <tbody>
                                <tr class="font-weight-boldest">
                                    <td class=" pt-7 d-flex ">
                                        {descrizioneGenerale}
                                    </td>
                                </tr> 
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div class="row justify-content-center  px-8  px-md-0">
                    <div class="col-md-10">
                        <div class="table-responsive">
                            <table class="table">
                                {abilitaVista ? createUIElementiSemplificata() : user.role === 'admin' || user.role === 'magazziniere' ? createUIElementiAdmin() : createUIElementi()}
                            </table>
                        </div>
                    </div>
                </div>
                <div class="row justify-content-center bg-gray-100 px-8 px-md-0 mx-0">
                <div class="mt-10 col-md-10">
                <div class="table-responsive">
                            <table class="table">
                                <thead>
                                    <tr>
                                        <th class="font-weight-bold   text-uppercase">NOTE PREVENTIVO</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr class="font-weight-bolder">
                                        <td>{notePreventivo}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div class="row justify-content-center bg-gray-100 py-8 px-8 py-md-10 px-md-0 mx-0 pagina-immagine">
                <div class="mt-10 col-md-10">
                        <div class={`table-responsive ${ordineRiparazione ? 'noprint': null}`}>
                            <table class="table">
                            <tbody>
                                    <tr>
                                        <td class="text-left">Consulenza, progetto/i (di proprietà AR-CA arredamenti fino a stipula del contratto), preventivo/i</td>
                                        <td class={`text-right pr-0 ${costoProgetto.conteggiato ? '' : "prezzo-non-contato"}`}>{trasformaCifra(costoProgetto.prezzo)}€</td>
                                    </tr>
                                    <tr>
                                        <td class="text-left">Rilevo misure, disegni per architettonico, disegno per impianti, verifica esecuzione lavori</td>
                                        <td class={`text-right pr-0 ${costoMisure.conteggiato ? '' : "prezzo-non-contato"}`}>{trasformaCifra(costoMisure.prezzo)}€</td>
                                    </tr>
                                    <tr>
                                        <td class="text-left">Servizi di magazzinaggio, carico, trasporto, scarico al piano, sballaggio, montaggio, smaltimento imballi</td>
                                        <td class={`text-right pr-0 ${costoMagazzino.conteggiato ? '' : "prezzo-non-contato"}`}>{trasformaCifra(costoMagazzino.prezzo)}€</td>
                                    </tr>
                                    <tr>
                                        <td class="text-left">Eventuali servizi per smontaggio, smaltimento, riposizionamento, modifiche, trasloco, montaggio mobili/elettrodomestici del cliente (30,00€ per operaio)</td>
                                        {!costoSmontaggio.conteggiato && (costoSmontaggio.prezzo == 0 || !costoSmontaggio.prezzo) ?
                                            <td class="text-right pr-0 ">da definire</td>
                                            :
                                            <td class={`text-right pr-0 ${costoSmontaggio.conteggiato ? '' : "prezzo-non-contato"}`}>{trasformaCifra(costoSmontaggio.prezzo)}€</td>
                                        }
                                    </tr>
                                    <tr>
                                        <td class="text-left">Allacciamenti elettrici (montaggio lampade)</td>
                                        {!costoElettricista.conteggiato && (costoElettricista.prezzo == 0 || !costoElettricista.prezzo) ?
                                            <td class="text-right pr-0 ">da definire</td>
                                            :
                                            <td class={`text-right pr-0 ${costoElettricista.conteggiato ? '' : "prezzo-non-contato"}`}>{trasformaCifra(costoElettricista.prezzo)}€</td>
                                        }
                                    </tr>
                                    <tr>
                                        <td class="text-left">Allacciamenti idraulici (acqua e gas)</td>
                                        {!costoIdraulico.conteggiato && (costoIdraulico.prezzo == 0 || !costoIdraulico.prezzo) ?
                                            <td class="text-right pr-0 ">da definire</td>
                                            :
                                            <td class={`text-right pr-0 ${costoIdraulico.conteggiato ? '' : "prezzo-non-contato"}`}>{trasformaCifra(costoIdraulico.prezzo)}€</td>
                                        }
                                    </tr>
                                    <tr>
                                        <td class="text-left">Eventuale servizio per occupazione suolo pubblico ed elevatore o facchinaggio</td>
                                        {!costoElevatore.conteggiato && (costoElevatore.prezzo == 0 || !costoElevatore.prezzo) ?
                                            <td class="text-right pr-0 ">da definire</td>
                                            :
                                            <td class={`text-right pr-0 ${costoElevatore.conteggiato ? '' : "prezzo-non-contato"}`}>{trasformaCifra(costoElevatore.prezzo)}€</td>
                                        }
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    <div class={`table-responsive ${ordineRiparazione ? 'noprint': null}`}>
                        <table class="table">
                            <tbody>
                                <tr>
                                    <td colspan="4" class="border-0"></td>
                                    <td class="border-0 pt-0 pb-0 font-weight-bolder font-size-h5 text-right colore-totale">TOTALE COMMISSIONE (IVA INCL.)</td>
                                    <td class="border-0 pt-0 pb-0 font-weight-bolder font-size-h5 text-right pr-0 colore-totale">{trasformaCifra(totale)}€</td>
                                </tr>
                                <tr>
                                    <td colspan="4" class="border-0"></td>
                                    <td class={`border-0 font-weight-bolder text-right ${statoPagamento === 'acconto' || statoPagamento === "si"  ? "colore-caparra-pagata": "colore-caparra"}`}>CAPARRA CONFIRMATORIA</td>
                                    <td class={`border-0 font-weight-bolder text-right pr-0 ${statoPagamento === 'acconto' || statoPagamento === "si"  ? "colore-caparra-pagata": "colore-caparra"}`}>{trasformaCifra(acconto)}€
                                    {user.role === 'admin' ? <a onClick={() => { setAbilitaAccontiPersonalizzati(!abilitaAccontiPersonalizzati) }} class="btn btn-icon btn-light btn-sm mx-3 modifica-caparre noprint">
																				<span class="svg-icon svg-icon-md svg-icon-primary">
																					<svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
																						<g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
																							<rect x="0" y="0" width="24" height="24"></rect>
																							<path d="M12.2674799,18.2323597 L12.0084872,5.45852451 C12.0004303,5.06114792 12.1504154,4.6768183 12.4255037,4.38993949 L15.0030167,1.70195304 L17.5910752,4.40093695 C17.8599071,4.6812911 18.0095067,5.05499603 18.0083938,5.44341307 L17.9718262,18.2062508 C17.9694575,19.0329966 17.2985816,19.701953 16.4718324,19.701953 L13.7671717,19.701953 C12.9505952,19.701953 12.2840328,19.0487684 12.2674799,18.2323597 Z" fill="#000000" fill-rule="nonzero" transform="translate(14.701953, 10.701953) rotate(-135.000000) translate(-14.701953, -10.701953)"></path>
																							<path d="M12.9,2 C13.4522847,2 13.9,2.44771525 13.9,3 C13.9,3.55228475 13.4522847,4 12.9,4 L6,4 C4.8954305,4 4,4.8954305 4,6 L4,18 C4,19.1045695 4.8954305,20 6,20 L18,20 C19.1045695,20 20,19.1045695 20,18 L20,13 C20,12.4477153 20.4477153,12 21,12 C21.5522847,12 22,12.4477153 22,13 L22,18 C22,20.209139 20.209139,22 18,22 L6,22 C3.790861,22 2,20.209139 2,18 L2,6 C2,3.790861 3.790861,2 6,2 L12.9,2 Z" fill="#000000" fill-rule="nonzero" opacity="0.3"></path>
																						</g>
																					</svg>
																				</span>
																			</a> : null}
                                    </td>
                                </tr>
                                {abilitaAccontiPersonalizzati ? 
                                        <>
                                            <tr className="noprint">
                                                <td colspan="4" class="border-0"></td>
                                                <td class="border-0 pt-0 text-right">
                                                    <label>Opzione pers.</label> 
                                                    <input type="text" class="form-control" name="accontoPersonalizzato1" value={accontiPersonalizzati[0].nomeAcconto} placeholder="Inserisci nome" onChange={e => onChangeSetNomeAccontiPersonalizzatiPreventivo(e, 0)}/>  
                                                </td>
                                                <td class="border-0 pt-0 text-right pr-0">
                                                    <input type="number" class="form-control mt-8" name="accontoPersonalizzato1.cifraAcconto" value={accontiPersonalizzati[0].cifraAcconto} placeholder="Inserisci numero" onChange={e => onChangeSetCifraAccontiPersonalizzatiPreventivo(e, 0)}/>
                                                </td>
                                            </tr>
                                            <tr className="noprint">
                                                <td colspan="4" class="border-0"></td>
                                                <td class="border-0 pt-0 text-right">
                                                    <label>Opzione pers.</label> 
                                                    <input type="text" class="form-control" name="accontoPersonalizzato2" value={accontiPersonalizzati[1].nomeAcconto} placeholder="Inserisci nome" onChange={e => onChangeSetNomeAccontiPersonalizzatiPreventivo(e, 1)}/>  
                                                </td>
                                                <td class="border-0 pt-0 text-right pr-0">
                                                    <input type="number" class="form-control mt-8" name="accontoPersonalizzato2.cifraAcconto" value={accontiPersonalizzati[1].cifraAcconto} placeholder="Inserisci numero" onChange={e => onChangeSetCifraAccontiPersonalizzatiPreventivo(e, 1)}/>
                                                </td>
                                            </tr>
                                            </>
                                            :
                                            null
                                            }
                                            {!abilitaAccontiPersonalizzati && accontiPersonalizzati.length > 0 ? parseFloat(accontiPersonalizzati[0].cifraAcconto) > 0 ?
                                                <tr className="noprint">
                                                    <td colspan="4" class="border-0"></td>
                                                    <td class={`border-0 pt-0 font-weight-bolder text-right ${statoPagamento === 'acconto' || statoPagamento === "si"  ? "colore-caparra-pagata": "colore-caparra"}`}>{accontiPersonalizzati[0].nomeAcconto.toUpperCase()}</td>
                                                    <td class={`border-0 pt-0 font-weight-bolder text-right pr-0 ${statoPagamento === 'acconto' || statoPagamento === "si"  ? "colore-caparra-pagata": "colore-caparra"}`}>{trasformaCifra(accontiPersonalizzati[0].cifraAcconto)}€</td>
                                                </tr>
                                                : null
                                            : null}
                                        {!abilitaAccontiPersonalizzati && accontiPersonalizzati.length > 1 ? parseFloat(accontiPersonalizzati[1].cifraAcconto) > 0 ?
                                            <tr className="noprint">
                                                <td colspan="4" class="border-0"></td>
                                                <td class={`border-0 pt-0 font-weight-bolder text-right ${statoPagamento === 'acconto' || statoPagamento === "si"  ? "colore-caparra-pagata": "colore-caparra"}`}>{accontiPersonalizzati[1].nomeAcconto.toUpperCase()}</td>
                                                <td class={`border-0 pt-0 font-weight-bolder text-right pr-0 ${statoPagamento === 'acconto' || statoPagamento === "si"  ? "colore-caparra-pagata": "colore-caparra"}`}>{trasformaCifra(accontiPersonalizzati[1].cifraAcconto)}€</td>
                                            </tr>
                                            : null
                                            : null}
                                            {accontiPersonalizzati.length > 0 ? parseFloat(accontiPersonalizzati[0].cifraAcconto) > 0 ?
                                                <tr className="div-hide print-table-row">
                                                    <td colspan="4" class="border-0"></td>
                                                    <td class={`border-0 pt-0 font-weight-bolder text-right ${statoPagamento === 'acconto' || statoPagamento === "si"  ? "colore-caparra-pagata": "colore-caparra"}`}>{accontiPersonalizzati[0].nomeAcconto.toUpperCase()}</td>
                                                    <td class={`border-0 pt-0 font-weight-bolder text-right pr-0 ${statoPagamento === 'acconto' || statoPagamento === "si"  ? "colore-caparra-pagata": "colore-caparra"}`}>{trasformaCifra(accontiPersonalizzati[0].cifraAcconto)}€</td>
                                                </tr>
                                                : null
                                            : null}
                                        {accontiPersonalizzati.length > 1 ? parseFloat(accontiPersonalizzati[1].cifraAcconto) > 0 ?
                                            <tr className="div-hide print-table-row">
                                                <td colspan="4" class="border-0"></td>
                                                <td class={`border-0 pt-0 font-weight-bolder text-right ${statoPagamento === 'acconto' || statoPagamento === "si"  ? "colore-caparra-pagata": "colore-caparra"}`}>{accontiPersonalizzati[1].nomeAcconto.toUpperCase()}</td>
                                                <td class={`border-0 pt-0 font-weight-bolder text-right pr-0 ${statoPagamento === 'acconto' || statoPagamento === "si"  ? "colore-caparra-pagata": "colore-caparra"}`}>{trasformaCifra(accontiPersonalizzati[1].cifraAcconto)}€</td>
                                            </tr>
                                            : null
                                            : null}
                                </tbody>
                                    </table>
                                    <table class="table">
                                        <tbody>
                                            <tr>
                                                <td class={`border-0 pt-0 font-weight-bolder text-right ${statoPagamento === 'si' ? "colore-totale-pagato" : "colore-totale"}`}>
                                                    <span class="noprint float-right">SALDO ALL’ARRIVO MERCE NOSTRI MAGAZZINI</span>
                                                    <div class="wrap_saldo">
                                                        <div class="firma_saldo div-hide print">
                                                            <div class="rect_saldo-wrap">
                                                                <span class="rect_saldo"></span>
                                                                <div class="firma-saldo">.........................................</div>
                                                            </div>
                                                        </div>
                                                        <span class="div-hide print">SALDO ALL’ARRIVO MERCE NOSTRI MAGAZZINI</span>
                                                    </div></td>
                                                <td class={`border-0 pt-0 font-weight-bolder text-right pr-0 tot2 ${statoPagamento === 'si' ? "colore-totale-pagato" : "colore-totale"}`}>{trasformaCifra(saldo)}€</td>
                                            </tr>
                                        </tbody>
                                    </table>
                        </div>
                        <div class="table-responsive">
                            <table class="table">
                                <thead>
                                    <tr>
                                        <th class="font-weight-bold   text-uppercase">NOTE PAGAMENTO</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr class="font-weight-bolder">
                                        <td>{notePagamento}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="table-responsive">
                            <table class="table">
                                <thead>
                                    <tr>
                                        <th class="font-weight-bold   text-uppercase">PREVISIONE CONSEGNA INDICATIVA</th>
                                        <th class="font-weight-bold   text-uppercase">NOTE CONSEGNA</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr class="font-weight-bolder">
                                        {user.role === 'admin' ?
                                            <td className="noprint">
                                               <span className="mx-2">Da</span>
                                               <DatePicker
                                                    selected={dataConsegnaStart}
                                                    onChange={date => onChangeDataConsegnaStart(date)}
                                                    showTimeSelect
                                                    minTime={setHours(setMinutes(new Date(), 0), 8)}
                                                    maxTime={setHours(setMinutes(new Date(), 0), 20)}
                                                    className="field w-input form-control"
                                                    dateFormat='dd/MM/yyyy HH:mm'
                                                    locale="it"
                                                />
                                                <span className="mx-2">a</span>
                                                <DatePicker
                                                    selected={dataConsegnaEnd}
                                                    onChange={date => onChangeDataConsegnaEnd(date)}
                                                    showTimeSelect
                                                    minTime={setHours(setMinutes(new Date(), 0), 8)}
                                                    maxTime={setHours(setMinutes(new Date(), 0), 20)}
                                                    className="field w-input form-control"
                                                    dateFormat='dd/MM/yyyy HH:mm'
                                                    locale="it"
                                                />
                                                <select class="form-control mt-3" name="statoPreventivo" onChange={e => setDatiCliente({ ...datiCliente, idMontatore: e.target.value })}>
                                                    <option selected disabled >Seleziona montatore</option>
                                                    {montatori.map((montatore) => (
                                                        <option selected={montatore._id == idMontatore} value={montatore._id}>{montatore.cognome} - {montatore.nome}</option>
                                                    ))}
                                                </select>
                                            </td>
                                            :
                                            dataConsegnaStart ? 
                                            <td className="noprint">
                                                <Moment format='DD/MM/YYYY'>{dataConsegnaStart}</Moment> {dataConsegnaStart.getHours() < 12 ? '(mattino)' : '(pomeriggio)'}<br />
                                                <label>Montatore: {cognomeMontatore} - {nomeMontatore}</label><br />
                                            </td>
                                            :
                                            <td className="noprint">
                                                <label>Da definire</label><br />
                                            </td>
                                        }
                                        {dataConsegnaStart ?
                                        <td className="print div-hide">
                                            <label><Moment format='DD/MM/YYYY'>{dataConsegnaStart}</Moment></label><br />
                                        </td>
                                        :
                                        <td className="div-hide">
                                            Da definire
                                        </td>
                                        }
                                        <td class="noprint font-weight-bolder">
                                        <div class="col-lg-12 noprint">
                                            {user.role === 'magazziniere'? 
                                            note :
                                            <textarea type="text" class="form-control noprint" name="note" value={note} onChange={e => setDatiCliente({ ...datiCliente, note: e.target.value })} placeholder="Inserisci note" />
                                            }
                                        </div>
                                        </td>
                                        <td className="print div-hide">
                                        {note}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                {ordineRiparazione ? null :
                <div class={`row justify-content-center bg-gray-100 mx-0 ${metodoPagamento ? null : "noprint" }`}>
                <div class="col-md-10">
                    <div class="table-responsive">
                        <table class="table">
                            <thead>
                                <tr>
                                    <th class="font-weight-bold   text-uppercase">METODO DI PAGAMENTO</th>
                                    <th class="font-weight-bold   text-uppercase noprint">STATO PAGAMENTO</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr class="font-weight-bolder">
                                <td className="print div-hide">
                                            {metodoPagamento}
                                        </td>
                                    <td className="noprint">
                                        {user.role === 'magazziniere' ?
                                        metodoPagamento
                                        :
                                        <input type="text" class="form-control mt-3 noprint" name="metodoPagamento" value={metodoPagamento} placeholder="Inserisci metodo di pagamento" onChange={e => setDatiCliente({ ...datiCliente, metodoPagamento: e.target.value })}/>
                                        }
                                    </td>
                                    <td className="noprint">
                                    {user.role === 'magazziniere' ?
                                        statoPagamento === 'si' ? 'Saldo pagato' : statoPagamento === 'acconto' ? 'Caparra pagata' : statoPagamento === accontiPersonalizzati[0].nomeAcconto ?  accontiPersonalizzati[0].nomeAcconto : statoPagamento === accontiPersonalizzati[1].nomeAcconto ?  accontiPersonalizzati[1].nomeAcconto : 'Non pagato'
                                    :
                                    <select class="form-control mt-3" name="statoPagamento" onChange={e => setDatiCliente({ ...datiCliente, statoPagamento: e.target.value })}>
                                        <option selected={statoPagamento === 'no' ? true : false} value={'no'}>Non pagato</option>
                                        <option selected={statoPagamento === 'acconto' ? true : false} value={'acconto'}>Caparra pagata</option>
                                        {accontiPersonalizzati.length > 0 && parseFloat(accontiPersonalizzati[0].cifraAcconto) > 0 ?
                                                <option selected={statoPagamento === accontiPersonalizzati[0].nomeAcconto ? true : false} value={accontiPersonalizzati[0].nomeAcconto}>{accontiPersonalizzati[0].nomeAcconto}</option>
                                            : null}
                                            {accontiPersonalizzati.length > 1 && parseFloat(accontiPersonalizzati[1].cifraAcconto) > 0 ?
                                            <option selected={statoPagamento === accontiPersonalizzati[1].nomeAcconto ? true : false} value={accontiPersonalizzati[1].nomeAcconto}>{accontiPersonalizzati[1].nomeAcconto}</option>
                                            : null}
                                            <option selected={statoPagamento === 'si' ? true : false} value={'si'}>Saldo pagato</option>
                                    </select>
                                    }
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
                }
                <div class="row justify-content-center print div-hide">
                    <div class="col-md-6">
                        <div class="example">
                            <p>Il Venditore AR-CA Arredamenti srl &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</p>
                            <div class="example-preview">
                                <div class="mt-20">
                                    <div class="separator separator-dashed separator-border-2 mb-2"></div>
            
                                    </div>
        
        
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="example">
                                <p>Il Committente, <Moment format='DD/MM/YYYY'>{new Date()}</Moment> Pavia &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</p>
                                <div class="example-preview">
                                    <div class="mt-20">
                                        <div class="separator separator-dashed separator-border-2 mb-2"></div>
            
                                    </div>
        
                                </div>
    
                        </div>
                    </div>
                </div>
                {user.role === 'admin' || user.role === 'venditore' ?
                <div className="card-body noprint">
            <div className="col-lg-12 mb-15">
            <h3 className="font-size-lg text-dark font-weight-bold mb-6">Allegati disegno:</h3>
                    {allegati.map((allegato) => (
                            allegato.tipologia === 'disegno' ?
                                allegato.type === 'image/jpeg' || allegato.type === 'image/png' ?
                                <div class="symbol symbol-50 symbol-2by3 flex-shrink-0 mr-4 elemento-file">
                                    <div class="d-flex flex-column">
                                        <a href={allegato.url} target="_blank">
                                            <div class="symbol-label mb-3" style={{backgroundImage: "url(" + allegato.url + ")"}}>
                                            <span class="svg-icon svg-icon-lg mx-2"><i class="far fa-image"></i></span>
                                            </div>
                                            <div className="nome-pdf">
                                                <p style={{"margin-bottom": "0px !important"}}>{allegato.nomeOriginale}</p>
                                            </div>
                                        </a>
                                        <a onClick={()=> { onClickEliminaAllegato(allegato._id, idOrdine) }} class="btn btn-danger font-weight-bolder py-2 font-size-sm">Elimina</a>
                                    </div>   
                                </div> 
                                :
                                <div class="symbol symbol-50 symbol-2by3 flex-shrink-0 mr-4 elemento-file">
                                    <div class="d-flex flex-column">
                                    <a href={allegato.url} target="_blank">
                                        <div class="symbol-label mb-3" style={{backgroundImage: "url(" + allegato.url + ")"}}>
                                        <span class="svg-icon svg-icon-lg mx-2"><i class="fas fa-file-pdf"></i></span>PDF
                                        </div>
                                        <div className="nome-pdf">
                                                <p style={{"margin-bottom": "0px !important"}}>{allegato.nomeOriginale}</p>
                                            </div>
                                        </a>
                                        <a onClick={()=> { onClickEliminaAllegato(allegato._id, idOrdine) }} class="btn btn-danger font-weight-bolder py-2 font-size-sm">Elimina</a>
                                    </div>   
                                </div>   
                            : null
                            ))}
                            <div class="symbol symbol-50 symbol-2by3 flex-shrink-0 mr-4">
                            <label htmlFor="file-input-disegno">
                                    <div class="d-flex flex-column">
                                    <a>
                                            <div class="symbol-label mb-3 btn-light-primary">
                                            <span class="svg-icon svg-icon-lg mx-2"><i class="fas fa-file-upload"></i></span>
                                            <input style={{display: "none"}} id="file-input-disegno" ref={(ref) => { setAllegatoDisegno(ref)}} onChange={() => processNuovoAllegatoDisegno()} onClick={(event)=> { event.target.value = null }} type="file"/>  
                                            </div>
                                        </a>
                                    </div>  
                                    </label> 
                                </div> 
                    </div> 
                    <div className="col-lg-12 mb-15">
            <h3 className="font-size-lg text-dark font-weight-bold mb-6">Allegati conferme d'ordine:</h3>
                    {allegati.map((allegato) => (
                        allegato.tipologia === 'confermaordine' ?
                                allegato.type === 'image/jpeg' || allegato.type === 'image/png' ?
                                <div class="symbol symbol-50 symbol-2by3 flex-shrink-0 mr-4 elemento-file">
                                    <div class="d-flex flex-column">
                                        <a href={allegato.url} target="_blank">
                                            <div class="symbol-label mb-3" style={{backgroundImage: "url(" + allegato.url + ")"}}>
                                            <span class="svg-icon svg-icon-lg mx-2"><i class="far fa-image"></i></span>
                                            </div>
                                            <div className="nome-pdf">
                                                <p style={{"margin-bottom": "0px !important"}}>{allegato.nomeOriginale}</p>
                                            </div>
                                        </a>
                                        <a onClick={()=> { onClickEliminaAllegato(allegato._id, idOrdine) }} class="btn btn-danger font-weight-bolder py-2 font-size-sm">Elimina</a>
                                    </div>   
                                </div> 
                                :
                                <div class="symbol symbol-50 symbol-2by3 flex-shrink-0 mr-4 elemento-file">
                                    <div class="d-flex flex-column">
                                    <a href={allegato.url} target="_blank">
                                        <div class="symbol-label mb-3" style={{backgroundImage: "url(" + allegato.url + ")"}}>
                                        <span class="svg-icon svg-icon-lg mx-2"><i class="fas fa-file-pdf"></i></span>PDF
                                        </div>
                                        <div className="nome-pdf">
                                                <p style={{"margin-bottom": "0px !important"}}>{allegato.nomeOriginale}</p>
                                            </div>
                                        </a>
                                        <a onClick={()=> { onClickEliminaAllegato(allegato._id, idOrdine) }} class="btn btn-danger font-weight-bolder py-2 font-size-sm">Elimina</a>
                                    </div>   
                                </div>  
                                : null 
                            ))}
                            <div class="symbol symbol-50 symbol-2by3 flex-shrink-0 mr-4">
                            <label htmlFor="file-input-confermaordine">
                                    <div class="d-flex flex-column">
                                    <a>
                                            <div class="symbol-label mb-3 btn-light-primary">
                                            <span class="svg-icon svg-icon-lg mx-2"><i class="fas fa-file-upload"></i></span>
                                            <input style={{display: "none"}} id="file-input-confermaordine" ref={(ref) => { setAllegatoConfermaOrdine(ref)}} onChange={() => processNuovoAllegatoConfermaOrdine()} onClick={(event)=> { event.target.value = null }} type="file"/>  
                                            </div>
                                        </a>
                                    </div>  
                                    </label> 
                                </div> 
                    </div>
                    <div className="col-lg-12 mb-15">
            <h3 className="font-size-lg text-dark font-weight-bold mb-6">Allegati ordini fornitore:</h3>
                    {allegati.map((allegato) => (
                        allegato.tipologia === 'ordinefornitore' ?
                                allegato.type === 'image/jpeg' || allegato.type === 'image/png' ?
                                <div class="symbol symbol-50 symbol-2by3 flex-shrink-0 mr-4 elemento-file">
                                    <div class="d-flex flex-column">
                                        <a href={allegato.url} target="_blank">
                                            <div class="symbol-label mb-3" style={{backgroundImage: "url(" + allegato.url + ")"}}>
                                            <span class="svg-icon svg-icon-lg mx-2"><i class="far fa-image"></i></span>
                                            </div>
                                            <div className="nome-pdf">
                                                <p style={{"margin-bottom": "0px !important"}}>{allegato.nomeOriginale}</p>
                                            </div>
                                        </a>
                                        <a onClick={()=> { onClickEliminaAllegato(allegato._id, idOrdine) }} class="btn btn-danger font-weight-bolder py-2 font-size-sm">Elimina</a>
                                    </div>   
                                </div> 
                                :
                                <div class="symbol symbol-50 symbol-2by3 flex-shrink-0 mr-4 elemento-file">
                                    <div class="d-flex flex-column">
                                    <a href={allegato.url} target="_blank">
                                        <div class="symbol-label mb-3" style={{backgroundImage: "url(" + allegato.url + ")"}}>
                                        <span class="svg-icon svg-icon-lg mx-2"><i class="fas fa-file-pdf"></i></span>PDF
                                        </div>
                                        <div className="nome-pdf">
                                                <p style={{"margin-bottom": "0px !important"}}>{allegato.nomeOriginale}</p>
                                            </div>
                                        </a>
                                        <a onClick={()=> { onClickEliminaAllegato(allegato._id, idOrdine) }} class="btn btn-danger font-weight-bolder py-2 font-size-sm">Elimina</a>
                                    </div>   
                                </div>  
                                : null 
                            ))}
                            <div class="symbol symbol-50 symbol-2by3 flex-shrink-0 mr-4">
                            <label htmlFor="file-input-ordinefornitore">
                                    <div class="d-flex flex-column">
                                    <a>
                                            <div class="symbol-label mb-3 btn-light-primary">
                                            <span class="svg-icon svg-icon-lg mx-2"><i class="fas fa-file-upload"></i></span>
                                            <input style={{display: "none"}} id="file-input-ordinefornitore" ref={(ref) => { setAllegatoOrdineFornitore(ref)}} onChange={() => processNuovoAllegatoOrdineFornitore()} onClick={(event)=> { event.target.value = null }} type="file"/>  
                                            </div>
                                        </a>
                                    </div>  
                                    </label> 
                                </div> 
                    </div> 
                    <div className="col-lg-12 mb-15">
            <h3 className="font-size-lg text-dark font-weight-bold mb-6">Allegati rilievo misure:</h3>
                    {allegati.map((allegato) => (
                        allegato.tipologia === 'misure' ?
                                allegato.type === 'image/jpeg' || allegato.type === 'image/png' ?
                                <div class="symbol symbol-50 symbol-2by3 flex-shrink-0 mr-4 elemento-file">
                                    <div class="d-flex flex-column">
                                        <a href={allegato.url} target="_blank">
                                            <div class="symbol-label mb-3" style={{backgroundImage: "url(" + allegato.url + ")"}}>
                                            <span class="svg-icon svg-icon-lg mx-2"><i class="far fa-image"></i></span>
                                            </div>
                                            <div className="nome-pdf">
                                                <p style={{"margin-bottom": "0px !important"}}>{allegato.nomeOriginale}</p>
                                            </div>
                                        </a>
                                        <a onClick={()=> { onClickEliminaAllegato(allegato._id, idOrdine) }} class="btn btn-danger font-weight-bolder py-2 font-size-sm">Elimina</a>
                                    </div>   
                                </div> 
                                :
                                <div class="symbol symbol-50 symbol-2by3 flex-shrink-0 mr-4 elemento-file">
                                    <div class="d-flex flex-column">
                                    <a href={allegato.url} target="_blank">
                                        <div class="symbol-label mb-3" style={{backgroundImage: "url(" + allegato.url + ")"}}>
                                        <span class="svg-icon svg-icon-lg mx-2"><i class="fas fa-file-pdf"></i></span>PDF
                                        </div>
                                        <div>
                                            <p>{allegato.nomeOriginale}</p>
                                        </div>
                                        </a>
                                        <a onClick={()=> { onClickEliminaAllegato(allegato._id, idOrdine) }} class="btn btn-danger font-weight-bolder py-2 font-size-sm">Elimina</a>
                                    </div>   
                                </div>  
                                : null 
                            ))}
                            <div class="symbol symbol-50 symbol-2by3 flex-shrink-0 mr-4">
                            <label htmlFor="file-input-misure">
                                    <div class="d-flex flex-column">
                                    <a>
                                            <div class="symbol-label mb-3 btn-light-primary">
                                            <span class="svg-icon svg-icon-lg mx-2"><i class="fas fa-file-upload"></i></span>
                                            <input style={{display: "none"}} id="file-input-misure" ref={(ref) => { setAllegatoMisure(ref)}} onChange={() => processNuovoAllegatoMisure()} onClick={(event)=> { event.target.value = null }} type="file"/>  
                                            </div>
                                        </a>
                                    </div>  
                                    </label> 
                                </div> 
                    </div>                         
                    </div>
                    : 
                    <div class="row justify-content-center py-8 px-8 py-md-10 px-md-0 noprint">
                        <div class="col-md-10">
                        {allegati.findIndex(x => x.tipologia === 'disegno') === -1 ? null :
                            <div class="d-flex flex-column flex-root">
                                <span class="font-weight-bolder mb-2">Allegati disegno:</span>
                                <div class="d-flex mb-8">
                                    {ordine.allegati.map((allegato) => (
                                         allegato.tipologia === 'disegno' ?
                                        allegato.type === 'image/jpeg' || allegato.type === 'image/png' ?
                                            <div class="symbol symbol-70 flex-shrink-0 mr-4 bg-light">
                                                <a href={allegato.url} target="_blank">
                                                    <div class="symbol-label" style={{ backgroundImage: "url(" + allegato.url + ")" }}>
                                                        <span class="svg-icon svg-icon-lg mx-2"><i class="far fa-image"></i></span>
                                                    </div>
                                                    <div className="nome-pdf">
                                                <p style={{"margin-bottom": "0px !important"}}>{allegato.nomeOriginale}</p>
                                            </div>
                                                </a>
                                            </div>
                                            :
                                            <div>
                                            <div class="symbol symbol-70 flex-shrink-0">
                                                <a href={allegato.url} target="_blank" class="h-70px w-70px btn btn-light-primary d-flex flex-column flex-center font-weight-bolder p-0">
                                                    <span class="svg-icon svg-icon-lg m-0"><i class="fas fa-file-pdf"></i></span>                        PDF
                                        </a>
                                            </div>
                                            <div className="nome-pdf">
                                                <p style={{"margin-bottom": "0px !important"}}>{allegato.nomeOriginale}</p>
                                            </div>
                                            </div>
                                            : null
                                    ))}
                                </div>
                            </div>
                            }
                            {allegati.findIndex(x => x.tipologia === 'confermaordine') === -1 ? null :
                            <div class="d-flex flex-column flex-root">
                                <span class="font-weight-bolder mb-2">Allegati conferme d'ordine:</span>
                                <div class="d-flex mb-8">
                                    {ordine.allegati.map((allegato) => (
                                        allegato.tipologia === 'confermaordine' ?
                                        allegato.type === 'image/jpeg' || allegato.type === 'image/png' ?
                                            <div class="symbol symbol-70 flex-shrink-0 mr-4 bg-light">
                                                <a href={allegato.url} target="_blank">
                                                    <div class="symbol-label" style={{ backgroundImage: "url(" + allegato.url + ")" }}>
                                                        <span class="svg-icon svg-icon-lg mx-2"><i class="far fa-image"></i></span>
                                                    </div>
                                                    <div className="nome-pdf">
                                                <p style={{"margin-bottom": "0px !important"}}>{allegato.nomeOriginale}</p>
                                            </div>
                                                </a>
                                            </div>
                                            :
                                            <div>
                                            <div class="symbol symbol-70 flex-shrink-0">
                                                <a href={allegato.url} target="_blank" class="h-70px w-70px btn btn-light-primary d-flex flex-column flex-center font-weight-bolder p-0">
                                                    <span class="svg-icon svg-icon-lg m-0"><i class="fas fa-file-pdf"></i></span>                        PDF
                                        </a>
                                            </div>
                                            <div className="nome-pdf">
                                                <p style={{"margin-bottom": "0px !important"}}>{allegato.nomeOriginale}</p>
                                            </div>
                                            </div>
                                            : null
                                    ))}
                                </div>
                            </div>
                            }
                            {allegati.findIndex(x => x.tipologia === 'ordinefornitore') === -1 ? null :
                            <div class="d-flex flex-column flex-root">
                                <span class="font-weight-bolder mb-2">Allegati ordini fornitore:</span>
                                <div class="d-flex mb-8">
                                    {ordine.allegati.map((allegato) => (
                                        allegato.tipologia === 'ordinefornitore' ?
                                        allegato.type === 'image/jpeg' || allegato.type === 'image/png' ?
                                            <div class="symbol symbol-70 flex-shrink-0 mr-4 bg-light">
                                                <a href={allegato.url} target="_blank">
                                                    <div class="symbol-label" style={{ backgroundImage: "url(" + allegato.url + ")" }}>
                                                        <span class="svg-icon svg-icon-lg mx-2"><i class="far fa-image"></i></span>
                                                    </div>
                                                    <div className="nome-pdf">
                                                <p style={{"margin-bottom": "0px !important"}}>{allegato.nomeOriginale}</p>
                                            </div>
                                                </a>
                                            </div>
                                            :
                                            <div>
                                            <div class="symbol symbol-70 flex-shrink-0">
                                                <a href={allegato.url} target="_blank" class="h-70px w-70px btn btn-light-primary d-flex flex-column flex-center font-weight-bolder p-0">
                                                    <span class="svg-icon svg-icon-lg m-0"><i class="fas fa-file-pdf"></i></span>                        PDF
                                        </a>
                                            </div>
                                            <div>
                                            <p>{allegato.nomeOriginale}</p>
                                        </div>
                                        </div>
                                            : null
                                    ))}
                                </div>
                            </div>
                            }
                            {allegati.findIndex(x => x.tipologia === 'misure') === -1 ? null :
                            <div class="d-flex flex-column flex-root">
                                <span class="font-weight-bolder mb-2">Allegati rilievo misure:</span>
                                <div class="d-flex mb-8">
                                    {ordine.allegati.map((allegato) => (
                                        allegato.tipologia === 'misure' ?
                                        allegato.type === 'image/jpeg' || allegato.type === 'image/png' ?
                                            <div class="symbol symbol-70 flex-shrink-0 mr-4 bg-light">
                                                <a href={allegato.url} target="_blank">
                                                    <div class="symbol-label" style={{ backgroundImage: "url(" + allegato.url + ")" }}>
                                                        <span class="svg-icon svg-icon-lg mx-2"><i class="far fa-image"></i></span>
                                                    </div>
                                                    <div className="nome-pdf">
                                                <p style={{"margin-bottom": "0px !important"}}>{allegato.nomeOriginale}</p>
                                            </div>
                                                </a>
                                            </div>
                                            :
                                            <div>
                                            <div class="symbol symbol-70 flex-shrink-0">
                                                <a href={allegato.url} target="_blank" class="h-70px w-70px btn btn-light-primary d-flex flex-column flex-center font-weight-bolder p-0">
                                                    <span class="svg-icon svg-icon-lg m-0"><i class="fas fa-file-pdf"></i></span>                        PDF
                                        </a>
                                            </div>
                                            <div>
                                            <p>{allegato.nomeOriginale}</p>
                                        </div>
                                        </div>
                                            : null
                                    ))}
                                </div>
                            </div>
                            }
                        </div>
                    </div>
                    }

<div class="card-body noprint">
                        <div class="option_stampa col-lg-12">
                            <label class="checkbox">
                                <input type="checkbox" checked={abilitaVista} onChange={e => onChangeSetVistaStampa(e)} />
                                    <span></span> 
                                    Vista semplice (non visualizza magazzino)
                            </label>
                        </div>
                    </div>
                <div class="row justify-content-center py-8 px-8 py-md-10 px-md-0 noprint">
                    <div class="col-md-10">
                        <div class="d-flex justify-content-between">
                            <button class='btn btn-secondary font-weight-bold' onClick={() => onClickChiudiOrdine(false)}>Indietro</button>
                            {user.role === 'magazziniere' ? null :
                            <Fragment>
                                <button class="btn btn-info font-weight-bold" onClick={() => window.print()}>Stampa</button>
                                <button class="btn btn-info font-weight-bold" onClick={() => openDocumenti()}>Stampa Dati</button>
                            </Fragment>
                            } 
                            {user.role === 'admin' ?
                                <Fragment>
                                    <button class="btn btn-warning font-weight-bold" onClick={() => onClickAnnullaOrdine(idOrdine)}>Annulla Consegna</button>
                                    {stato === 'attesadataconsegna' ? null : 
                                    <button class='btn btn-success font-weight-bold' onClick={() => setOrdineConsegnato(idOrdine, dataConsegnaStart, dataConsegnaEnd, idMontatore, elementi, metodoPagamento, statoPagamento, note)}>Segna come consegnato</button>
                                    }
                                    <button class='btn btn-primary font-weight-bold salva-btn' onClick={() => setDataConsegnaOrdine(idOrdine, dataConsegnaStart, dataConsegnaEnd, idMontatore, elementi, metodoPagamento, statoPagamento, note, accontiPersonalizzati, saldo, totale)}>Salva modifiche</button>
                                </Fragment>
                                : null}
                                {user.role === 'magazziniere' ?
                                <button class='btn btn-primary font-weight-bold salva-btn' onClick={() => onClickModificaMagazzino()}>Salva modifiche magazzino</button>
                            : null}
                                {user.role === 'venditore'  && ordine.venditore._id === user._id ? 
                                <button class='btn btn-primary font-weight-bold salva-btn' onClick={() => onClickModificaPagamentoNote()}>Salva modifiche</button>
                            : null
                            }
                            {user.role === 'admin' ? 
                                <button class="btn btn-warning font-weight-bold" onClick={() => onClickOpenModificaOrdine()}>Modifica Ordine</button>
                                :
                                ''
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="print div-hide pagina-immagine ">
        <img  src={condizioniGenerali} width="97%" height="auto"/>
        </div>
        </Fragment>
    )
}

OrdineConsegna.protoTypes = {
    setDataConsegnaOrdine: PropTypes.func.isRequired,
    setOrdineConsegnato: PropTypes.func.isRequired,
    uploadAllegatiOrdine: PropTypes.func.isRequired,
    uploadNuovoAllegato: PropTypes.func.isRequired,
    eliminaAllegato: PropTypes.func.isRequired,
    setOrdineConsegnato: PropTypes.func.isRequired,
    modificaPagamentoNote: PropTypes.func.isRequired,
    modificaMagazzino: PropTypes.func.isRequired,
    getMontatori: PropTypes.func.isRequired,
    annullaConsegnaOrdine: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    montatore: PropTypes.object.isRequired,
    allegati: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth,
    montatore: state.montatore,
    allegati: state.ordine.allegati
});

export default connect(mapStateToProps, { setDataConsegnaOrdine, setOrdineConsegnato, uploadAllegatiOrdine, uploadNuovoAllegato, eliminaAllegato, getMontatori, modificaPagamentoNote, modificaMagazzino, annullaConsegnaOrdine })(OrdineConsegna);

