import React, { useEffect, useState, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Moment from 'react-moment'
import Spinner from '../../layout/Spinner';
import { switchSidebarLogo, trasformaCifra, getDcumentoPrint } from '../../../utility/Utility';
import { getOrdine, cleanOrdine } from '../../../actions/ordine';
const OrdineMontatore = ({ auth: { user }, ordine: { ordine, loading }, getOrdine, idOrdine, onClickChiudiOrdine, cleanOrdine }) => {
    
    const [datiCliente, setDatiCliente] = useState({
        pIvaCodiceFiscale: '',
        nome: '',
        cognome: '',
        telefono_1: '',
        telefono_2: '',
        telefono_3: '',
        telefono_4: '',
        email_1: '',
        email_2: '',
        indirizzo: '',
        cap: '',
        citta: '',
        indirizzoPreventivo: '',
        capPreventivo: '',
        cittaPreventivo: '',
        pianoPreventivo: '',
        scalaPreventivo: '',
        ascensorePreventivo: '',
        ztlPreventivo: '',
        nomePuntoVendita: '',
        indirizzoPuntoVendita: '',
        telefonoPuntoVendita: '',
        emailPuntoVendita: '',
        sitoWebPuntoVendita: '',
        emailVenditore: '',
        numero: '',
        date: '',
        magazzino: '',
        misure: '',
        fattura: '',
        elementi: [],
        acconto: '',
        accontiPersonalizzati : [
            {
                nomeAcconto:'',
                cifraAcconto: null
            },{
                nomeAcconto:'',
                cifraAcconto: null
            }
        ],
        saldo: '',
        totale: '',
        dataConsegnaStart: new Date(),
        dataConsegnaEnd: new Date(),
        nomeMontatore: '',
        cognomeMontatore: '',
        ordineRiparazione: false,
        dataConfermaPreventivo: new Date(),
        costoProgetto: 0,
        costoMisure: 0,
        costoMagazzino: 0,
        costoSmontaggio: 0,
        costoElettricista: 0,
        costoElevatore: 0,
        fileDocumenti: ''
    });

    useEffect(() => {
        getOrdine(idOrdine);
        return () => {
            cleanOrdine();
        }
    }, [getOrdine, cleanOrdine]);

    useEffect(() => {
        setDatiCliente({
            pIvaCodiceFiscale: loading || !ordine ? '' : ordine.cliente.pIvaCodiceFiscale,
            nome: loading || !ordine ? '' : ordine.cliente.nome,
            cognome: loading || !ordine ? '' : ordine.cliente.cognome,
            telefono_1: !ordine ? '' : ordine.cliente.telefono_1,
            telefono_2: !ordine ? '' : ordine.cliente.telefono_2,
            telefono_3: !ordine ? '' : ordine.cliente.telefono_3,
            telefono_4: !ordine ? '' : ordine.cliente.telefono_4,
            email_1: loading || !ordine ? '' : ordine.cliente.email_1,
            email_2: loading || !ordine ? '' : ordine.cliente.email_2,
            indirizzo: !ordine ? '' : ordine.cliente.indirizzo,
            cap: !ordine ? '' : ordine.cliente.cap,
            citta: !ordine ? '' : ordine.cliente.citta,
            indirizzoPreventivo: loading || !ordine ? '' : ordine.indirizzoPreventivo,
            capPreventivo: loading || !ordine ? '' : ordine.capPreventivo,
            cittaPreventivo: loading || !ordine ? '' : ordine.cittaPreventivo,
            pianoPreventivo: loading || !ordine ? '' : ordine.pianoPreventivo,
            scalaPreventivo: loading || !ordine ? '' : ordine.scalaPreventivo,
            ascensorePreventivo: loading || !ordine ? '' : ordine.ascensorePreventivo,
            ztlPreventivo: loading || !ordine ? '' : ordine.ztlPreventivo,
            nomePuntoVendita: loading || !ordine ? '' : ordine.puntoVendita.nome,
            nomePuntoVenditaDb: loading || !ordine ? '' : ordine.puntoVendita.nomeDb,
            indirizzoPuntoVendita: loading || !ordine ? '' : ordine.puntoVendita.indirizzo,
            telefonoPuntoVendita: loading || !ordine ? '' : ordine.puntoVendita.telefono,
            emailPuntoVendita: loading || !ordine ? '' : ordine.puntoVendita.email,
            sitoWebPuntoVendita: loading || !ordine ? '' : ordine.puntoVendita.sitoWeb,
            emailVenditore: loading || !ordine ? '' : ordine.venditore.email,
            fileDocumenti: loading || !ordine ? '' : ordine.venditore.fileDocumenti,
            numero: loading || !ordine ? '' : ordine.numero,
            date: loading || !ordine ? '' : ordine.date,
            misure: loading || !ordine ? '' : ordine.misure,
            fattura: loading || !ordine ? '' : ordine.fattura,
            elementi: loading || !ordine ? '' : ordine.elementi,
            acconto: loading || !ordine ? '' : ordine.acconto,
            accontiPersonalizzati: !ordine || !ordine.accontiPersonalizzati || ordine.accontiPersonalizzati.length <= 0 ? [
                {
                    nomeAcconto:'',
                    cifraAcconto: null
                },{
                    nomeAcconto:'',
                    cifraAcconto: null
                }
            ] : ordine.accontiPersonalizzati,
            saldo: loading || !ordine ? '' : ordine.saldo,
            totale: loading || !ordine ? '' : ordine.totale,
            dataConsegnaStart: loading || !ordine ||  ordine.dataConsegnaStart === undefined ? new Date() : new Date(ordine.dataConsegnaStart),
            dataConsegnaEnd: loading || !ordine ||  ordine.dataConsegnaEnd === undefined ? new Date() : new Date(ordine.dataConsegnaEnd),
            nomeMontatore: loading || !ordine ? '' : ordine.montatore.nome,
            cognomeMontatore: loading || !ordine ? '' : ordine.montatore.nome,
            ordineRiparazione: loading || !ordine ? '' : ordine.ordineRiparazione,
            dataConfermaPreventivo: loading || !ordine ||  ordine.dataConfermaPreventivo === undefined ? new Date() : new Date(ordine.dataConfermaPreventivo),
            costoProgetto: !ordine ? 0 : ordine.costoProgetto,
            costoMisure: !ordine ? 0 : ordine.costoMisure,
            costoMagazzino: !ordine ? 0 : ordine.costoMagazzino,
            costoSmontaggio: !ordine ? 0 : ordine.costoSmontaggio,
            costoElettricista: !ordine ? 0 : ordine.costoElettricista,
            costoElevatore: !ordine ? 0 : ordine.costoElevatore
        });
    }, [ordine, loading]);

    const { pIvaCodiceFiscale, nome, cognome, telefono_1, telefono_2, telefono_3, telefono_4, email_1, email_2, indirizzo, cap, citta, indirizzoPreventivo, capPreventivo, cittaPreventivo, pianoPreventivo, scalaPreventivo, ascensorePreventivo, ztlPreventivo, nomePuntoVendita, nomePuntoVenditaDb, indirizzoPuntoVendita, telefonoPuntoVendita, emailPuntoVendita, sitoWebPuntoVendita, emailVenditore, numero, date, misure, fattura, elementi, acconto, accontiPersonalizzati, saldo, totale, dataConsegnaStart, dataConsegnaEnd, nomeMontatore, cognomeMontatore, ordineRiparazione, dataConfermaPreventivo, costoProgetto, costoMisure, costoMagazzino, costoSmontaggio, costoElettricista, costoElevatore } = datiCliente;

    const createUIElementi = () => {
        return(
            <Fragment>
            <thead>
                    <tr>
                        <th class="pl-0 font-weight-bold   text-uppercase">Descrizione</th>
                        <th class="text-right font-weight-bold  text-uppercase"><span></span></th>
                        <th class="text-right pr-0 font-weight-bold  text-uppercase">Prezzo</th> 
                    </tr>
            </thead>
            <tbody>
            {elementi.length > 0 ? 
                elementi.map((elemento) => (
                        <tr class="font-weight-boldest">
                            <td class=" pt-7 d-flex ">{elemento.descrizione}</td>
                            <td class="text-right pt-7 align-middle"><span></span></td>
                            <td class="text-right pt-7 align-middle">{trasformaCifra(elemento.prezzo)}€</td>
                        </tr>     
                )) : null
            }
            </tbody>   
        </Fragment>     
        )
    }
    
    return ( loading || !ordine ? <Spinner /> :
        <div className="card card-custom card-shadowless rounded-top-0" id="preventivo">
            <div class="card-body p-0">  
            <iframe id="ifmcontentstoprint" style={{height: "0px", width: "0px", position: "absolute"}}>
            </iframe>
                <div class="row justify-content-center py-8 px-8 py-md-10 px-md-0">
                    <div class="col-md-10">
                        <div class="d-flex justify-content-between pb-10 pb-md-20 flex-column flex-md-row">
                        <div class="d-flex flex-column flex-root">
                        <h1 class="display-5 font-weight-boldest mb-10"><span style={{color: 'red'}}>{ordineRiparazione ? 'ASSISTENZA ' : null}</span>COMMISSIONE N. {numero}</h1>
                        <span class="font-weight-bolder mb-2"><span class="mr-2">DATA COMMISSIONE: </span>
                                <span class=""><Moment format='DD/MM/YYYY'>{dataConfermaPreventivo}</Moment></span> </span>
                                <span class="font-weight-bolder mb-2"><span class="mr-2">FATTURA</span>
                                <span class="">{fattura ? "Si" : 'No'}</span></span>
                            <h2 className="nominativo_allineato">{cognome} {nome}</h2>
                            </div>
                            <div class="d-flex flex-column align-items-md-end px-0 logo-mobile">
                            <img alt="Logo" src={switchSidebarLogo(nomePuntoVenditaDb, user.role, 'grande')} className="max-h-150px" />
                                <h1>{nomePuntoVendita}</h1>
                                <span class=" d-flex flex-column align-items-md-end ">
                                    <span>{indirizzoPuntoVendita}</span>
                                    <span>{telefonoPuntoVendita}</span>
                                    <span>Ufficio consegne: 0382 573181</span>
                                    <span>{emailVenditore}</span>
                                    <span>{sitoWebPuntoVendita}</span>
                                    {nomePuntoVenditaDb !== 'arca_arredamenti' ?
                                        <span style={{"color":"red"}}>Instestato a: ARCA ARREDAMENTI CASA SRL</span>
                                    : 
                                        ''
                                    }    
                                    <span style={{"color":"red"}}>Intesa Sanpaolo - IBAN: IT 82O0 3069 1133 4100 00000 2034</span>
                                    <span style={{"color":"red"}}>Intesa Sanpaolo - BIC: BCITITMM</span>
                                </span>
                            </div>
                        </div>
                        <div class="border-bottom w-100"></div>
                        <div class="d-flex justify-content-between pt-6 colonna-mobile mb-5">
                        <div class="d-flex flex-column flex-root">
                                <span class="font-weight-bolder mb-2">NOMINATIVO</span>
                                <div>Cognome Nome: <span className="text-nome-cognome">{cognome} {nome}</span></div>
                                {pIvaCodiceFiscale ? 
                                    <span class="">P.Iva/C.F: {pIvaCodiceFiscale}</span>
                                : null }
                                <div>Tel 1: <span className="text-nome-cognome">{telefono_1}</span></div>
                                {telefono_2 ? 
                                <div>Tel 2: <span className="text-nome-cognome">{telefono_2}</span></div>
                                : null }
                                {telefono_3 ? 
                                <div>Tel 3: <span className="text-nome-cognome">{telefono_3}</span></div>
                                : null }
                                {telefono_4 ? 
                                <div>Tel 4: <span className="text-nome-cognome">{telefono_4}</span></div>
                                : null }
                                {email_1 ? 
                                    <span class="">Email 1: {email_1}</span>
                                : null }
                                {email_2 ? 
                                    <span class="">Email 2: {email_2}</span>
                                : null }
                                {indirizzo || cap || citta ? 
                                <span class="">Indirizzo: {indirizzo} {cap}, {citta}</span>
                                : null }
                            </div>
                            <div class="d-flex flex-column flex-root">
                                <span class="font-weight-bolder mb-2">INDIRIZZO CONSEGNA</span>
                                {indirizzoPreventivo || capPreventivo || cittaPreventivo ? 
                                    <span class="">Indirizzo: {indirizzoPreventivo}  {capPreventivo}, {cittaPreventivo}</span>
                                : null }
                                {pianoPreventivo ? 
                                    <span class="">Piano: {pianoPreventivo}</span>
                                : null }
                                {scalaPreventivo ? 
                                    <span class="">Scala: {scalaPreventivo}</span>
                                : null }
                                    <span class="">ZTL: {ztlPreventivo ? 'Si' : 'No'}</span>
                                    <span class="">Ascensore: {ascensorePreventivo ? 'Si' : 'No'}</span>
                            </div>
                        </div>
                        <div class="d-flex justify-content-between pt-6">
                            <div class="d-flex flex-column flex-root">
                            <span class="font-weight-bolder mb-2"><span class="mr-2">MISURE</span>
                            <span class="">{misure === 'cliente'  ? "Comunicate dall'acquirente" : 'A carico del venditore'}</span></span>
                        </div>
                        <div class="d-flex flex-column flex-root">
                        </div>
                        </div>
                    </div>
                </div>
                <div class="row justify-content-center  px-8  px-md-0">
                    <div class="col-md-10">
                        <div class="table-responsive">
                            <table class="table">
                                    {createUIElementi()}
                            </table>
                        </div>
                    </div>
                </div>
                <div class="row justify-content-center bg-gray-100 py-8 px-8 py-md-10 px-md-0 mx-0">
                    <div class="col-md-10">
                        <div class="table-responsive">
                        <table class="table">
                                <thead>
                                    <tr>
                                        <th class="font-weight-bold text-uppercase">DATA CONSEGNA</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr class="font-weight-bolder">
                                        <td>
                                        <Moment format='DD/MM/YYYY'>{dataConsegnaStart}</Moment>
                                        </td>
                                    </tr>
                                    <tr class="font-weight-bolder">
                                        <label>Montatori: {cognomeMontatore} - {nomeMontatore}</label>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                {ordine.allegati.length >0 && ordine.allegati.findIndex(x => x.tipologia === 'disegno') !== -1 ? 
                <div class="row justify-content-center py-8 px-8  px-md-0">
                    <div class="col-md-10">
                        <div class="d-flex flex-column flex-root">
                            <span class="font-weight-bolder mb-2">Allegati disegno:</span>
                            <div class="d-flex mb-8">
                            {ordine.allegati.map((allegato) => (
                                allegato.tipologia === 'disegno' ?
                                allegato.type === 'image/jpeg' || allegato.type === 'image/png' ?
                                    <div class="symbol symbol-70 flex-shrink-0 mr-4 bg-light">
                                        <a href={allegato.url} target="_blank">
                                            <div class="symbol-label" style={{backgroundImage: "url(" + allegato.url + ")"}}>
                                            <span class="svg-icon svg-icon-lg mx-2"><i class="far fa-image"></i></span>
                                            </div>
                                            <div className="nome-pdf">
                                                <p style={{"margin-bottom": "0px !important"}}>{allegato.nomeOriginale}</p>
                                            </div>
                                        </a>
                                    </div>
                                :
                                <div>
                                    <div class="symbol symbol-70 flex-shrink-0">
                                        <a href={allegato.url} target="_blank" class="h-70px w-70px btn btn-light-primary d-flex flex-column flex-center font-weight-bolder p-0">
                                            <span class="svg-icon svg-icon-lg m-0"><i class="fas fa-file-pdf"></i></span>                        PDF
                                        </a>
                                    </div>   
                                    <div>
                                    <p>{allegato.nomeOriginale}</p>
                                </div>
                                </div>
                                    
                                    : null
                            ))}
                        </div>
                    </div>
                </div>
                </div>
                : null}
                <div class="row justify-content-center  px-8  px-md-0">
                    <div class="col-md-10">
                        <div class="mt-8 mb-8 d-flex justify-content-between">
                            <button class='btn btn-secondary font-weight-bold' onClick={ ()=> onClickChiudiOrdine(false)}>Indietro</button>
                        </div>
                    </div>
                </div>   
            </div>
        </div>
    )
}

OrdineMontatore.protoTypes = {
    getOrdine: PropTypes.func.isRequired,
    cleanOrdine: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    ordine: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    ordine: state.ordine,
    auth: state.auth
});

export default connect(mapStateToProps, { getOrdine, cleanOrdine })(OrdineMontatore);

